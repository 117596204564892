<div class="card" style="z-index: 0;">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fak fa-reportscleanid fa-lg mr-2 grey4 font-weight-normal"></i>Field Management</span>
    <i class="fa-light fa-square-arrow-right fa-lg go-to-page-icon float-right cursor-pointer" (click)="openSettings('dates')"></i>
  </div>
  <div class="card-body nav-font14 pt-2">
    <div class="row grey4 pb-3">
      <div class="col-5 nav-secondary">Metric overview</div>
      <div class="col pl-0">
        <span class="grey3">
          {{_project.fieldEndDate ? 'Ends ' +  (_project.fieldEndDate | date: 'MM/dd/yyyy') : 'No field end date'}}
        </span>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col mt-1">
        <div class="metric">
          <div class="grey6 nav-font14">
            <div class="row">
              <div class="col nav-font12 grey6 font-weight-bold">Completes</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <div class="rule">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      [ngClass]="ProgressStyle"
                      [style.width.%]="(complete * 100/fullLaunchQuota )"
                      aria-valuenow=""
                      aria-valuemin="0"
                      aria-valuemax="100">
                    </div>
                    <div class="vl" [style.right.%]="100 - daysPercentagePast"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nav-font14 pl-3">
            <div class="row">
              <div class="col nav-font12 grey4 pr-0">Expected: {{ fullLaunchQuota }}n</div>
            </div>
            <div class="row pt-2">
              <div class="col nav-font12 grey4 pr-0">Current: {{ complete }}n</div>
            </div>
          </div>
        </div>
        <hr class="my-3">
        <div class="metric">
          <div class="grey6 nav-font14">
            <div class="row">
              <div class="col nav-font12 grey6 font-weight-bold">Incidence Rate (IR)</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <div class="rule">
                  @if (_data.length == 0) {
                    <or-progress-guage mode="split" [value]="_project.projectSegments[0].projectIR" [expected]="_project.projectSegments[0].projectIR"></or-progress-guage>
                  }
                  @if (_data.length > 0) {
                    <or-progress-guage mode="split" [value]="(actualIR == 0 ) ? _project.projectSegments[0].projectIR : actualIR * 100" [expected]="_project.projectSegments[0].projectIR"></or-progress-guage>
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="nav-font14 pl-3">
            <div class="row">
              <div class="col nav-font12 grey4 pr-0">Expected: {{ (_project.projectSegments[0].projectIR || 0) + '%'}}</div>
            </div>
            <div class="row pt-2">
              <div class="col nav-font12 grey4 pr-0">Current: {{ actualIR | percent }}</div>
            </div>
          </div>
        </div>
        <hr class="my-3">
        <div class="metric">
          <div class="grey6 nav-font14">
            <div class="row">
              <div class="col nav-font12 grey6 font-weight-bold">Length of Interview (LOI)</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <div class="rule">
                  @if (_data.length == 0) {
                    <or-progress-guage mode="split" [value]="_project.projectSegments[0].projectLOI * 60" [expected]="_project.projectSegments[0].projectLOI * 60"></or-progress-guage>
                  }
                  @if (_data.length > 0) {
                    <or-progress-guage mode="split" [value]="(medianLoi == 0 ) ? _project.projectSegments[0].projectLOI * 60 : medianLoi" [expected]="_project.projectSegments[0].projectLOI * 60"></or-progress-guage>
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="nav-font14 pl-3">
            <div class="row">
              <div class="col nav-font12 grey4 pr-0">Expected: {{(_project.projectSegments[0].projectLOI || 0 ) + ' min'}}</div>
            </div>
            <div class="row pt-2">
              <div class="col nav-font12 grey4 pr-0">Current: {{ medianLoi | minuteSeconds }}</div>
            </div>
          </div>
        </div>
        <hr class="my-3">
        <div class="metric">
          <div class="grey6 nav-font14">
            <div class="row">
              <div class="col nav-font12 grey6 font-weight-bold">Quality Control (Manual QCs)</div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <div class="rule">
                  @if (_data.length == 0) {
                    <or-progress-guage [value]="0" [expected]="_project.alertQCRate"></or-progress-guage>
                  }
                  @if (_data.length > 0) {
                    <or-progress-guage [value]="qcRate * 100" [expected]="_project.alertQCRate"></or-progress-guage>
                  }
                </div>
              </div>
            </div>
          </div>
          <div class="nav-font14 pl-3">
            <div class="row">
              <div class="col nav-font12 grey4 pr-0">Expected: {{(_project.alertQCRate || 0 ) + '%'}}</div>
            </div>
            <div class="row pt-2">
              <div class="col nav-font12 grey4 pr-0">Current: {{ qcRate | percent}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


