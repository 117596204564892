<div class="card">
  <div class="card-header d-flex align-items-center" [ngClass]="respondents?.length > 0 ? 'pb-1' : 'pb-2'">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fas fa-light fa-shield-check fa-lg mr-2 grey4 font-weight-normal"></i>Quality</span>
    <i class="fa-light fa-square-arrow-right fa-lg go-to-page-icon float-right cursor-pointer" (click)="openQuality()"></i>
  </div>
  <div class="card-body px-3 pt-1" [ngClass]="respondents?.length > 0 ? 'pt-1' : 'pt-2 pl-4'">
    @if (respondents?.length > 0) {
      <div class="row d-flex align-items-center justify-content-center mx-0">
        <div class="nav-secondary nav-font14 font-weight-bold mr-3">Data Acceptance Rate</div>
        <div class="">
          <span class="dar-circle">
            <span class="nav-secondary nav-font20 font-weight-bold">{{clientQcs == 0 ? '100%' : (completes/(completes+clientQcs) | percent)}}</span>
          </span>
        </div>
      </div>
    }
    @else {
      <span class="nav-font16 grey5">No data to report</span>
    }
  </div>
</div>
