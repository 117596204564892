import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, HostListener, ViewChild} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DndDropEvent } from 'ngx-drag-drop';
import { ProjectQuotaMetricsComponent } from '../project-quota/project-quota-metrics/project-quota-metrics.component';

@Component({
  selector: 'app-project-homepage',
  templateUrl: './project-homepage.component.html',
  styleUrls: ['./project-homepage.component.scss']
})
export class ProjectHomepageComponent implements OnInit, AfterViewInit {

  @Input() public set data(data) {
    this._project = data?.project;
    this.respondents = data?.respondents;
  }

  @Input() public set stage(data) {
    this._stage = data;
    this.updateGridRowSpan();
  }

  @Input() _stage: any;
  @Output() callback = new EventEmitter();
  
  openModalRef: any;
  
  _project:any = {};
  respondents: any = [];
  cols =  [];
  colWidth = 360;

  constructor(private modalService: BsModalService) {
   }

  ngOnInit() {
    this.updateGridRowSpan();
  }

  ngAfterViewInit() {
    this.updateGridRowSpan();
  }


  updateGridRowSpan(): void {

    let homepageCards = this._stage?.homepageCards;

    if (!homepageCards || homepageCards.length === 0) return;

    const cardElement = document.getElementsByClassName('homepageCardContainer').item(0);
    if (!cardElement) return;

    let cardsWidth = cardElement.getBoundingClientRect().width;
    let colCount = Math.floor(cardsWidth / this.colWidth);
    if (colCount < 3) colCount = 3;

    this.cols = [];
    for (let colIdx = 0; colIdx < colCount; colIdx++) {
      this.cols.push({ index: colIdx, cards: [] });
    }
    let orderedCards = this.getOrderedCards(homepageCards);
    orderedCards.forEach((card, idx) => {
      const targetCol = idx % colCount;
      const currentCol = this.cols[targetCol];
      currentCol.cards.push(card);
    });

  }

  getOrderedCards(homepageCards: any[]): any[] {

    const savedOrderKey = `projectHomepageGridOrder_${this._stage.name}`;
    let savedOrder = JSON.parse(localStorage.getItem(savedOrderKey)) || [];

    if(savedOrder == null) return homepageCards;
    //set all homepageCards sortOrder to 0
    homepageCards.forEach(c => c.sortOrder = 0);
    //set sortOrder based on savedOrder
    savedOrder.forEach((savedCard, idx) => {
      let card = homepageCards.find(c => c.id === savedCard.id);
      if(card) {
        card.sortOrder = idx + 1;
      }
    });
    //add increment sortOrder for cards not in savedOrder
    let sortOrder = savedOrder.length + 1;
    homepageCards.forEach(c => {
      if(c.sortOrder === 0) {
        c.sortOrder = sortOrder;
        sortOrder++;
      }
    });
    return homepageCards.sort((a, b) => a.sortOrder - b.sortOrder);;

  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateGridRowSpan();
  }


  cardCallback(evt) {
    this.callback.emit(evt);
  }


  onDrop(event: DndDropEvent) {

    const target = event.event.target as HTMLElement;
    const targetParentWithSortOrder = target.closest('.item');
    const targetSortOrder = parseInt(targetParentWithSortOrder.getAttribute('sortOrder'));

    const draggedCardId = event.data.id;
    const draggedCardIndex = this._stage.homepageCards.findIndex((c) => c.id === draggedCardId);

    // Remove the dragged card from the list
    let [draggedCard] = this._stage.homepageCards.splice(draggedCardIndex, 1);

    let newCards:any = [];
    let inserted = false;
    let newSortOrder = 1;

    this._stage.homepageCards.forEach((card) => {
      if (newSortOrder === targetSortOrder && !inserted) {
        draggedCard.sortOrder = newSortOrder++;
        newCards.push(draggedCard);
        inserted = true;
      }

      card.sortOrder = newSortOrder++;
      newCards.push(card);
    });

    if (!inserted) {
      draggedCard.sortOrder = newSortOrder++;
      newCards.push(draggedCard);
    }

    this._stage.homepageCards = newCards;
    this._stage.homepageCards.sort((a, b) => a.sortOrder - b.sortOrder);

    this.saveHomepageCards();
    this.updateGridRowSpan();
  }

  saveHomepageCards(): void {
    const savedOrderKey = `projectHomepageGridOrder_${this._stage.name}`;
    const orderedCards = this._stage.homepageCards.map((card) => ({ id: card.id, type: card.type }));
    localStorage.setItem(savedOrderKey, JSON.stringify(orderedCards));
  }

  openQuotaMetricsModal() {
    this.openModalRef = this.modalService.show(ProjectQuotaMetricsComponent, {
      ignoreBackdropClick: true, 
      keyboard: false,
      initialState: {
          project: this._project,
          respondents: this.respondents,
          fullscreen: true,
      },
      
      class: 'modal-xl modal-project-quota-metrics',
      backdrop: 'static',
    });
  }
}
