<h3 class="font-weight-semi-bold nav-font18 grey5 px-4">
  <i class="fas fa-file-arrow-down grey4 mr-2 font-weight-normal"></i>Exports
</h3>

<div class="mt-4 downloads scrollbar overflow-y px-3">

  <div class="item my-2 p-2">
    <div class="d-flex align-items-center justify-content-center pb-2">
      <h5 class="grey5 font-weight-bold mb-0">Full Data Export</h5>
      <i class="fak fa-download fa-md ml-auto cursor-pointer" tooltip="Export" container="body" (click)="download('full')"></i>
    </div>
    <div class="nav-black2 nav-font14">This export will provide all fields available (General, Device, CleanID, ResponseID, Quotas, Passthrough Variables)</div>
  </div>

  <div class="item my-2 p-2">
    <div class="d-flex align-items-center justify-content-center pb-2">
      <h5 class="grey5 font-weight-bold mb-0">Project Participant Export</h5>
      <i class="fak fa-download fa-md ml-auto cursor-pointer" tooltip="Export" container="body" (click)="download('participant')"></i>
    </div>
    <div class="nav-black2 nav-font14">This export provides participant details across the project</div>
  </div>

  @if (bidNumber == ''){
    <div class="d-flex align-items-center justify-content-center mt-4">
      <span class="grey4 nav-font14">Vendors</span>
      <span class="nav-accent-blue nav-font12 cursor-pointer ml-auto" (click)="downloadPartnerZip()">Export All</span>
    </div>
    @for (partner of partnersList; track partner) {
      <div class="item my-2 p-2" >
        <div class="d-flex align-items-center justify-content-center pb-2">
          <h5 class="grey5 font-weight-bold mb-0">{{ partner.partnerName }}</h5>
          <i class="fak fa-download fa-md ml-auto cursor-pointer" tooltip="Export" container="body" (click)="downloadByPartner(partner)"></i>
        </div>
        <div class="nav-black2 nav-font14">This report provides participant details from this vendor Excel</div>
      </div>
    }
  }
 
</div>
