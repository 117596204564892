<div class="card">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fak fa-team fa-lg mr-2 grey4 font-weight-normal"></i>Team</span>
    <i class="fa-light fa-square-arrow-right fa-lg go-to-page-icon float-right cursor-pointer" (click)="openSettings('team')"></i>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <div class="mb-2 cursor-pointer" [ngClass]="!_project.pm ? 'circle add-value' : ''" (click)="(!_project.pm) ? openSettings('team') : null">
              @if (!_project.pm) {
                <i class="fa fa-plus fa-lg nav-accent-blue font-weight-normal"></i>
              }
              @if (_project.pm) {
                <or-profile-image [names]="[{ name: _project.pm, type: 'pm' }]" showInitials="true" [showRole]="false" size="medium"></or-profile-image>
              }
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <span class="d-block grey5 nav-font12 font-weight-bold">Primary PM</span>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <div class="mb-2 cursor-pointer" [ngClass]="secondaryPms.length == 0 ? 'circle add-value' : ''" (click)="(secondaryPms.length == 0) ? openSettings('team') : null">
              @if (secondaryPms.length == 0) {
                <i class="fa fa-plus fa-lg nav-accent-blue font-weight-normal"></i>
              }
              @if (secondaryPms.length > 0) {
                <or-profile-image [names]="secondaryPms" showPlus="true" [showRole]="false" showInitials="true" size="medium"></or-profile-image>
              }
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <span class="d-block grey5 nav-font12 font-weight-bold">Secondary PM</span>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-center pr-0">
            <div class="mb-2 cursor-pointer" [ngClass]="programmers.length == 0 ? 'circle add-value' : ''" (click)="(programmers.length == 0) ? openSettings('team') : null">
              @if (programmers.length == 0) {
                <i class="fa fa-plus fa-lg nav-accent-blue font-weight-normal"></i>
              }
              @if (programmers.length > 0) {
                <or-profile-image [names]="programmers" showPlus="true" showInitials="true" [showRole]="false" size="medium"></or-profile-image>
              }
            </div>
          </div>
        </div>
        <div class="row justify-content-center pl-3">
          <span class="d-block grey5 nav-font12 font-weight-bold">Programmer</span>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <div class="col d-flex justify-content-center">
            <div class="mb-2 cursor-pointer" [ngClass]="sales.length == 0 ? 'circle add-value' : ''" (click)="(sales.length == 0) ? openSettings('team') : null">
              @if (sales.length == 0) {
                <i class="fa fa-plus fa-lg nav-accent-blue font-weight-normal"></i>
              }
              @if (sales.length > 0) {
                <or-profile-image [names]="sales" showInitials="true" [showRole]="false" size="medium"></or-profile-image>
              }
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <span class="d-block grey5 nav-font12 font-weight-bold">Sales</span>
        </div>
      </div>
    </div>
  </div>
</div>
