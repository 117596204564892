import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, Renderer2 } from '@angular/core';
import { AlertifyService, ProjectService, AuthService, QuotaService, ProjectQuota, QuotaVendorLimit, ProjectDataService } from 'core';
import { v4 as uuidv4 } from 'uuid';
import {  forkJoin, timeout } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as Highcharts from 'highcharts';
import { time } from 'console';

@Component({
  selector: 'app-project-quota-metrics',
  templateUrl: './project-quota-metrics.component.html',
  styleUrls: ['./project-quota-metrics.component.scss']
})
export class ProjectQuotaMetricsComponent implements OnInit, OnChanges {

  highcharts = Highcharts;
  highchartsOptions: Highcharts.Options;

  @ViewChild('modal')
  modalRefConfirm: BsModalRef;
  openModalRef: any;

  @Input() project;
  @Input() respondents;
  @Input() fullscreen;
  @Input() source = 'homepage';

  @Output() openQuotaMetricsModal = new EventEmitter();
  @Output() goToQuotaSetup = new EventEmitter();

  isVendorOrClient = true;
  isVendor = true;
  isClient = true;
  instanceId: string;
  metrics = {starts: 0, completes: 0}
  justSaved = true;
  hhiMax = 500000;

  // quota questions
  questions;

  // quota card
  projectQuota: ProjectQuota = null;
  quotaGroupTotals = {};
  daysPercentagePast = 0;
  vendorGroupMetrics = {}
  vendors;


  constructor(
    private alertify: AlertifyService,
    public auth: AuthService,
    private projectService: ProjectService,
    private quotaService: QuotaService,
    private projectDataService: ProjectDataService,
    private renderer: Renderer2,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.getQuota();

    const daysLeft = (this.project.totalDaysLeft < 0 ) ? 0 : this.project.totalDaysLeft;
    const totalDays = this.project.totalDays;
    this.daysPercentagePast = 100 - Math.round(daysLeft * 100 / totalDays)

    var isVendorView = false
    var isClientView = false

    this.projectDataService.getVendorViewSidebar.subscribe(data => {
      if (data) {
        isVendorView = data != '';
      }
      this.isVendorOrClient = this.auth.isClient() || this.auth.isVendor() || isVendorView || isClientView
      this.isVendor = this.auth.isVendor() || isVendorView;
    });
    this.projectDataService.getClientViewSidebar.subscribe(data => {
      if (data) {
        isClientView = data != '';
      }
      this.isVendorOrClient = this.auth.isClient() || this.auth.isVendor() || isVendorView || isClientView
      this.isClient = this.auth.isClient() || isClientView;
    });
  }

  ngOnChanges( changes ) {
    if (changes.fullscreen && changes.fullscreen.currentValue != changes.fullscreen.previousValue) {
      window.scroll(0, 0);
    }
  }

  openInModal() {
    this.openQuotaMetricsModal.emit();
  }

  getQuota()
  {
    forkJoin([this.quotaService.GetQuota(this.project.id), this.quotaService.ListQuestions()]).subscribe((data) => {

      this.vendors = this.project.projectSegments[0].projectSurveyPartners
      .filter(x => x.isDeleted == false)
      .sort((a, b) => a.partnerName.localeCompare(b.partnerName));

      this.processData(this.respondents);

      this.questions = {};

      data[1].forEach(q => {
        if (q.type !== 'Division') {
          this.questions[q.id] = q;
        }
      })

      if (data[0] != null) {
        this.processQuota(data[0]);
      }
    },
      error => {
        this.alertify.error('There was an error getting quota details');
      },);
  }


  processData(data) {
    if (data.length > 0) {
      let summary = {starts: 0, completes : 0};
      data.forEach(element => {

        summary.completes += element.complete
        summary.starts += element.starts
      });

      var vendorAllocationTotal = this.vendors.filter(x => !x.isDeleted).reduce((sum, item) => sum + item.partnerFullQuota, 0);

      this.vendors.forEach(vendor => {
        vendor.starts = 0;
        vendor.completes = 0;
        data.filter(x => x.partnerId === vendor.partnerId).forEach(element => {
          vendor.starts += element.starts;
          vendor.completes += element.complete;
        });

        vendor.totalFullQuotaPercent = vendor.partnerFullQuota > 0 ? Math.ceil(vendor.partnerFullQuota * 100 / vendorAllocationTotal) : 0;

      });
      this.metrics = summary;
    }
    if(this.project.id == "d583bf3a-ab7e-441f-b1a1-07c96bb3e2e6") {
      //Dummy data for demo
      this.metrics.starts = 23513;
      this.metrics.completes = 4000;
      this.vendors.forEach(vendor => {
        if(vendor.partnerName == "Lucid Holdings, Inc") {
          vendor.starts = 10616;
          vendor.completes = 1921;
        }
        else  if(vendor.partnerName == "Cint") {
          vendor.starts = 6465;
          vendor.completes = 1217;
        }
        else  if(vendor.partnerName == "TapResearch Inc") {
          vendor.starts = 6383;
          vendor.completes = 852;
        }
        else  if(vendor.partnerName == "Prodege POD") {
          vendor.starts = 49;
          vendor.completes = 10;
        }
      });
    }
  }

  processQuota(projectQuota: ProjectQuota) {

    projectQuota.quotaGroups = projectQuota.quotaGroups.filter(x => x.name !== 'Division');
    if (this.project.id == "d583bf3a-ab7e-441f-b1a1-07c96bb3e2e6") {
      projectQuota.quotaGroups.forEach(qg => {
        qg.quotas.forEach(q => {
          let percent = Math.floor(Math.random() * 25 + 80)/100;
          q.completes = q.limit * percent;

          //random allocation to each vendor
          let arr = Array.from({length: 5}, () => Math.floor(Math.random() * 100));
          let sum = arr.reduce((a, b) => a + b, 0);
          arr = arr.map(x => Math.floor(x / sum * 100));
          let idx = 0;
          q.vendorLimits.forEach(vendor => {
            vendor.completes = Math.floor(q.completes * arr[idx++]/100);
          });
        });
      });
    }
    this.projectQuota = projectQuota;
    this.sort(this.projectQuota.quotaGroups, 'order')
    var quotasWithOptionAnswers = ['Gender', 'Ethnicity', 'Hispanic']
    var i = 0;
    this.projectQuota.quotaGroups.forEach(qg => {
      qg.limitN = 0;
      qg.quotas = qg.quotas.filter(x => !x.isDeleted);
      var qIds = qg.questionId.split(',');
      qg.nameArray = [];
      qIds.forEach(qId => {
        qg.nameArray.push(this.questions[qId]?.type);
      });

      qg.completes = 0;
      qg.starts = 0;

      if (qg.name === 'Income') this.sort(qg.quotas, 'minHHI');
      else if (qg.name === 'Age') this.sort(qg.quotas, 'minAge');
      else this.sort(qg.quotas, 'order');

      qg.quotas.forEach(q => {
        q.groupId = qg.id;

        // handle the nested pnta quotas
        if (qg.nameArray.length > 1) {
          var pntaCount = 0;
          var index = 0;
          qg.nameArray.forEach(nestedGroup => {
            var value = null;
            if (nestedGroup === 'HouseholdIncome') {
              value = q.targetGroup.minHHI == -1 ? 'pnta' : q.targetGroup.minHHI;
            }
            else if (nestedGroup === 'Age') {
              value = q.targetGroup.minAge == -1 ? 'pnta' : q.targetGroup.minAge;
            }
            else {
              value = q.targetGroup[nestedGroup.toLocaleLowerCase()];
            }
            if (value === 'pnta') pntaCount++;
            index++;
          });
          if (pntaCount >= 1 && pntaCount < qg.nameArray.length ) {
            q.hideQuota  = true;
          }
          else if (pntaCount === qg.nameArray.length) {
            q.isPNTA = true;
          }
        }

        if (!q.hideQuota) {
          qg.completes += q.completes;
          qg.starts += q.starts;

          var quotaValue;

          if (quotasWithOptionAnswers.includes(qg.name)) {
            quotaValue = q.targetGroup[qg.name.toLocaleLowerCase()];
            q.order = this.questions[qg.questionId].options.find(x => x.value === quotaValue)?.order;
          }
          else {
            if (qg.name === 'Income' && q.targetGroup.minHHI == -1) quotaValue = 'pnta';
            for (let qId in this.questions) {
              if (this.questions[qId].type === qg.name) {
                if (qg.name == 'Region') q.order =  this.questions[qId].defaults.find(x => x.value === q.targetGroup[qg.name.toLocaleLowerCase()]).order
              }
            }
          }

          q.limitN = Math.ceil(this.project.fullLaunchQuota * q.limit / 100);
          q.limitPercent = q.limit;
          qg.limitN += q.limitN;
          i++;
          this.addVendorsToQuota(q);
        }
      });

      if (qg.name === 'Income') this.sort(qg.quotas, 'minHHI');
      else if (qg.name === 'Age') this.sort(qg.quotas, 'minAge');
      else this.sort(qg.quotas, 'order');

      qg.nonValueStarts = this.metrics.starts > 0 ? (this.metrics.starts - qg.starts) || 0 : 0;
      qg.nonValueCompletes = this.metrics.completes ? (this.metrics.completes - qg.completes) || 0 : 0;

      qg.starts = qg.starts + qg.nonValueStarts;
      qg.completes = qg.completes + qg.nonValueCompletes;
    });

    this.createQuotaStackedChart();

  }

  addVendorsToQuota(quota) {
    const vendorLimits = [];
    this.vendors.forEach(vendor => {
      // For every vendor on this project we add the vendor limits if specified
      var existing = quota.vendorLimits.find(v => v.vendorId === vendor.partnerId);
      
      if (existing) {
        existing.limitN = Math.ceil(vendor.partnerFullQuota * existing.limit / 100);
        existing.limitPercent = existing.limit;
      }
      else {
        existing = {
          id: uuidv4(),
          vendorId: vendor.partnerId,
          limitPercent: quota.limit,
          limitN: 0,
          limit: quota.limit,
          completes: 0,
          starts: 0
        };
        existing.limitN = Math.ceil(vendor.partnerFullQuota * existing.limitPercent / 100);
      }
        if (!this.vendorGroupMetrics[existing.vendorId+'_'+quota.groupId]) {
          this.vendorGroupMetrics[existing.vendorId+'_'+quota.groupId] = {starts: existing.starts, completes: existing.completes}
        }
        else {
          this.vendorGroupMetrics[existing.vendorId+'_'+quota.groupId].starts += existing.starts;
          this.vendorGroupMetrics[existing.vendorId+'_'+quota.groupId].completes += existing.completes;
        }
        vendorLimits.push(existing);
    });

    quota.vendorLimits = vendorLimits;
  }

  sort(array, sortBy) {
    var A;
    var B
    array.sort((a, b) => {

      if (sortBy === 'order')
      {
        A = a.order;
        B = b.order;
      }
      else if (sortBy === 'min')
      {
        A = a.min
        B = b.min
      }
      else if (sortBy === 'minAge')
      {
        A = a.targetGroup.minAge
        B = b.targetGroup.minAge
      }
      else if (sortBy === 'minHHI')
      {
        A = a.targetGroup.minHHI
        B = b.targetGroup.minHHI
        if (A == -1) A =999999999999
        if (B == -1) B =999999999999
      }

      let comparison = 0;
      if (A > B) {
        comparison = 1;
      } else if (A < B) {
        comparison = -1;
      }
      return comparison;
    });
  }

  getBgColor(value): string {
    var valueWithFlex = (value + this.projectQuota.distributionFlexibility || 0);

    if (this.daysPercentagePast > (valueWithFlex + 10)) {
      return 'nav-bg-error';
    }
    else if (this.daysPercentagePast > (valueWithFlex + 5) && this.daysPercentagePast <= (valueWithFlex + 10)) {
      return 'nav-bg-warning';
    }
    else {
      return 'nav-success';
    }
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }


  createQuotaStackedChart() {
    let chartData : any = [{
      name: 'Off track',
      data: [],
      className: 'off-track',
      color: '#D54A4C',
      opacity: 1,
    }, {
        name: 'Falling behind',
        data: [],
        className: 'falling-behind',
        color: '#FDDA89',
        opacity: 1,
    },{
      name: 'On track',
      data: [],
      className: 'on-track',
      color: '#4CCD96',
      opacity: 1
    }]

    var categories = [];
    this.projectQuota?.quotaGroups.forEach(group => {
      var redCount = 0;
      var yellowCount = 0;
      var greenCount = 0;
      var name = group.name
      if (name.includes(',')) {
        name = name.replace(', ', ',<br>');
      }
      name =  name.replace('and', '&').replace('& ', '&<br>');
      categories.push(name);
      group.quotas.filter(x => !x.hideQuota && !x.isDeleted).forEach(quota => {
        var value = (quota[this.projectQuota.limitMode+'s'] * 100) / quota.limitN;
        var colour = this.getBgColor(value);
        if (colour == 'nav-success') greenCount++;
        else if (colour == 'nav-bg-warning') yellowCount++;
        else redCount++;
      });
      chartData[0].data.push(redCount);
      chartData[1].data.push(yellowCount);
      chartData[2].data.push(greenCount);
    });

    while (categories.length < 6) {
      categories.push('')
    }
    while (chartData[0].data.length < 6) {
      chartData[0].data.push(0)
    }
    while (chartData[1].data.length < 6) {
      chartData[1].data.push(0)
    }
    while (chartData[2].data.length < 6) {
      chartData[2].data.push(0)
    }

    this.highchartsOptions = {
      credits: {
        enabled: false
      },
      chart: {
        // marginTop: 10,
        // spacingTop: 20,
        type: 'column',
        style: {
          fontFamily: 'Lato, sans-serif',
        },
        animation: false,
        plotBorderWidth: null,
        plotBackgroundColor: null,
        plotShadow: false,
        events: {
          render: function () {
          },
          load: this.onChartLoad.bind(this),
        },
      },
      title: null,

      xAxis: {
          categories: categories,
          gridZIndex:0,
          gridLineWidth: 0,
          labels: {
            style: {
              color: '#464C54',
              fontSize: '10px',
            },
            overflow: 'allow',
            distance: -18,
            autoRotation: [0]
          },

      },
      yAxis: {
          min: -20,
          max: 102,
          title: null,
          tickInterval: 20,
          tickPositions: [-20, 0, 20, 40, 60, 80, 100, 120],
          showFirstLabel: false,
          showLastLabel: false,
          minPadding: 5,
          minorTicks: true,
          minorTickInterval: 10,
          minorTickColor: '#E0E0E0',
          minorTickPosition: 'inside',
          minorTickWidth: 1,
          minorGridLineColor: '#E0E0E0',
          minorTickLength: 260,
          gridLineWidth: 1,
          gridLineColor: '#E0E0E0',
          labels: {
            format: '{value} %',
            style: {
              color: '#5D6A7D',
              fontSize: '10px',
            }
          }

      },
      tooltip: {
          enabled: false
      },
      plotOptions: {

        column: {
          showInLegend: false,
          stacking: 'percent',
          dataLabels: {
              enabled: false,
          },
          pointWidth: 24,
          borderRadius: 0,
        },
        series: {
          states: {
            inactive: {
              enabled: false
            },
            hover: {
              enabled: false,
            },
          },
          point: {
            events: {
              mouseOver: function (this, e) {
                const chart = this.series.chart;

                var className = this.color == '#D54A4C' ? 'off-track' : this.color == '#FDDA89' ? 'falling-behind' : 'on-track';
                let element = document.getElementsByClassName(className);
                element[0].parentNode.appendChild(element[0])
                element[0].parentNode.appendChild(element[0]) // adding a second time because sometimes it doesn't work ??

                this.update({
                  dataLabels: {
                    enabled: true,
                    format: `{point.percentage:.0f}%`,
                    useHTML: true,
                    style: {
                      zIndex: 1,
                        color: this.color == '#D54A4C' ? 'white' : '#333333',
                        fontSize: '10px',
                        textDecoration: 'none',
                        fontWeight: 'normal',
                        fontFamily: 'Lato, sans-serif',
                        textOutline: 'none',
                        className: this.color == '#D54A4C' ? 'off-track' : this.color == '#FDDA89' ? 'falling-behind' : 'on-track',
                    },
                  },
                },);
                this.graphic.animate({
                  r: 5,
                  x: this.shapeArgs.x - 2,
                  y: this.shapeArgs.y - (this.shapeArgs.height < 6 ? (11 - this.shapeArgs.height)/2 : 2),
                  width: this.shapeArgs.width + 4,
                  height: this.shapeArgs.height + (this.shapeArgs.height < 6 ? (11 - this.shapeArgs.height) : 4),
                }, {
                  duration: 200
                });

                this.graphic.shadow({
                  color: 'rgba(1, 59, 92, 0.16)', // Shadow color
                  offsetX: 0, // Shadow offset X
                  offsetY: 3, // Shadow offset Y
                  width: 1, // Shadow width
                  opacity: .1, // Shadow opacity
                });

              },
              mouseOut: function (this, event) {
                const chart = this.series.chart;

                this.graphic.animate({
                  r: 0,
                  x: this.shapeArgs.x,
                  y: this.shapeArgs.y,
                  width: this.shapeArgs.width,
                  height: this.shapeArgs.height
                }, {
                  duration: 0
                });
                this.update({
                  dataLabels: {
                    enabled: false}
                });
              },

            },
          },
        },
      },
      series: chartData
    }
  }

  onChartLoad(event) {
    let offTrack = document.getElementsByClassName('off-track');
    let fallingBehind = document.getElementsByClassName('falling-behind');
    let onTrack = document.getElementsByClassName('on-track');

    this.removeSomeLines(); // this is a workaround to chnage the column height during animation without being cut off

    for (let i = 0; i < offTrack[0]?.childNodes.length; i++) {
      var el = offTrack[0]?.childNodes[i];
      if (el) {
        this.renderer.setStyle(el, 'fill-opacity', 1);
      }
    }
    for (let i = 0; i < fallingBehind[0].childNodes.length; i++) {
      var el = fallingBehind[0]?.childNodes[i];
      if (el) {
        this.renderer.setStyle(el, 'fill-opacity', 1);
      }
    }
    for (let i = 0; i < onTrack[0].childNodes.length; i++) {
      var el = onTrack[0]?.childNodes[i];
      if (el) {
        this.renderer.setStyle(el, 'fill-opacity', 1);
      }
    }
  }

  removeSomeLines() {
    let gridLines = document.getElementsByClassName('highcharts-yaxis-grid')[0];
    gridLines?.removeChild(gridLines.childNodes[0]);
    gridLines?.removeChild(gridLines.childNodes[0]);
    gridLines?.removeChild(gridLines.childNodes[0]);
    gridLines?.removeChild(gridLines.childNodes[10]);
    gridLines?.removeChild(gridLines.childNodes[11]);
    gridLines?.removeChild(gridLines.childNodes[12]);
    gridLines?.removeChild(gridLines.childNodes[13]);
    gridLines?.removeChild(gridLines.childNodes[gridLines.childNodes.length - 1]);

    let xAxis = document.getElementsByClassName('highcharts-xaxis')[0];
    xAxis?.removeChild(xAxis.childNodes[0]);


    let minorGridLines = document.getElementsByClassName('highcharts-yaxis')[0];
    minorGridLines.removeChild(minorGridLines.childNodes[0]);
    minorGridLines.removeChild(minorGridLines.childNodes[0]);
    minorGridLines.removeChild(minorGridLines.childNodes[minorGridLines.childNodes.length - 1]);
    minorGridLines.removeChild(minorGridLines.childNodes[minorGridLines.childNodes.length - 1]);
    minorGridLines.removeChild(minorGridLines.childNodes[minorGridLines.childNodes.length - 1]);
  }

  openQuotasSetup() {

    if (!this.fullscreen) {
      this.goToQuotaSetup.emit();
    }
    else {
      if (this.source !== 'quota-setup') {
        this.projectService.switchSection({section: 'quotas', type: null});
      }
      this.bsModalRef.hide();
    }
  }
}

