<div class="container-fluid" [class.vendorAndClientView]="isVendorOrClient">
  <div class="row d-flex align-items-center mx-0 mb-4">
    <div class="col p-0">
      <span class="nav-secondary nav-font18 font-weight-bold ">Quotas</span>
    </div>
  </div>

    <div class="row mx-0 mb-4 d-flex align-items-center quotas-header">
      @if (!isVendorOrClient) {
        <!-- add quotas button dropdown -->
        <div class="btn shadow-none nav-btn-accent quota-add-btn px-2 py-0 btn-group position-relative mr-3" dropdown dropdownToggle #dropdownDemographic="bs-dropdown"
        id="button-basic" controls="dropdown-basic"
        [class.active]="dropdownDemographic.isOpen"
        [class.disabledBtn]="project.fullLaunchQuota == 0 || quotaCantBeChanged"
        placement="bottom"
        containerClass="disabled-btn-tooltip"
        [tooltip]="project.fullLaunchQuota == 0 ? 'Cannot add quotas while project completes allocation is 0' : (quotaCantBeChanged ? 'Project must be in Paused status in order to make changes' : null)" >
        <!-- dropdown btn -->
        <span class="nav-font14 font-weight-bold">Add Quotas</span>
        <i class="px-1 ml-1 fa fa-light fa-pipe"></i>
        <i class="fas ml-1 font-weight-normal nav-font16" [class.fa-chevron-down]="!dropdownDemographic.isOpen" [class.fa-chevron-up]="dropdownDemographic.isOpen" ></i>
        <!-- dropdown menu -->
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right nav-font14 position-absolute" role="menu" aria-labelledby="button-basic">
          <div class="section">
            <div class="section-name">Add new</div>
            <div class="option mx-2 px-2 my-1 pt-1" (click)="metrics.liveStarts > 0 ? openModal(modal, 'confirm-add') : openModalQuotaConfig('add')">
              <i class="icon fak fa-groups"></i>Demographics
            </div>
          </div>
          <hr class="separator">
          <div class="section">
            <div class="section-name">Add existing</div>
            <div class="option px-1 pt-1 m-1" (click)="openModal(modal, 'templateClone')">
              <i class="icon far fa-copy"></i>Clone from project
            </div>
            <div class="option px-1 pt-1 m-1" (click)="openModal(modal, 'templateImport')">
              <i class="icon fa-regular fa-file-import" ></i>Import from file
            </div>
          </div>
        </div>
        </div>
        @if (nonNestedGroups.length >= 2) {
        <div class="btn shadow-none nav-btn-primary quota-nest px-2 py-0 font-weight-bold
          nav-font14 d-flex align-items-center mr-3" (click)="quotaCantBeChanged ? null : openModalQuotaConfig('nest')" [class.disabledBtn]="quotaCantBeChanged"
          [tooltip]="quotaCantBeChanged ? 'Project must be in Paused status in order to make changes' : null" placement="bottom" containerClass="disabled-btn-tooltip">
          <i class="fak fa-nest grey4 nav-font20 font-weight-normal icon mr-2"></i>
          Nest Quotas
        </div>
        }
        <div class="btn shadow-none nav-btn-primary quota-download-template px-2 py-0 font-weight-bold
        nav-font14 d-flex align-items-center mr-3" (click)="downloadQuotaTemplate()">
        <i class="fa-kit fa-download-solid font-weight-normal grey4 nav-font16 icon mr-2 p-1"></i>
        Download template
        </div>
        @if (addedQuestions.length > 0) {
          <div class="btn shadow-none nav-btn-primary quota-settings px-2 py-0 font-weight-bold mr-3
            nav-font14 d-flex align-items-center" (click)="quotaCantBeChanged ? null : openModalQuotaConfig('edit')" [class.disabledBtn]="quotaCantBeChanged"
            [tooltip]="quotaCantBeChanged ? 'Project must be in Paused status in order to make changes' : null" placement="bottom" containerClass="disabled-btn-tooltip">
            <i class="fak fa-gear-solid grey4 nav-font20 font-weight-normal icon mr-2"></i>
            Settings
          </div>
        }
      }
      @if (addedQuestions.length > 0 && metrics.liveStarts > 0) {
        <div class="btn shadow-none nav-btn-primary quota-counts px-2 py-0 font-weight-bold
          nav-font14 d-flex align-items-center" (click)="openQuotaMetricsModal()">
          View Quota Summary
        </div>
        }   
    </div>

  <!-- quotas not enabled -->
  @if (addedQuestions.length == 0) {
    <div class="grey5 nav-font16 mt-3 pt-2">Add quota to begin.</div>
  }

  <!-- Quota Group Cards -->
  @if (addedQuestions.length > 0) {
    <div class="quota-cards d-flex">
      @for (col of cols; track col.index; let i = $index) {
        <div class="quota-grid" [style.margin-right.px]="i < cols.length - 1 ? 24 : 0">
          @for (group of col.cards; track card?.id) {
            @if ((questionIDs.includes(group.questionId) || 
              (questionIDs.includes(group.questionIdArray[0]) && questionIDs.includes(group.questionIdArray[1]) && (group.questionIdArray.length == 2 || questionIDs.includes(group.questionIdArray[2])))) && 
              !group.isDeleted) {
              <div class="grid">
                <ng-template *ngTemplateOutlet="quotaCard; context:{ $implicit: group}"></ng-template>
              </div>
            }
          }
        </div>
      }
    </div>
  }

  

  <!-- Vendor Quota Card -->
  @if (projectQuota?.enableVendorLimits && addedQuestions.length > 0 && !isClient) {
    <div class="vendor-quota-container mb-5 mt-2"  [class.overflow-y]="vendorCardOverflowY" [class.overflow-x]="vendorCardOverflowX">
      <div class="vendor-quotas-card first-row border-bottom border-top zIndex2" [style.width]="'min-content'" [style.grid-template-columns]="vendorCardGridTemplate">
        <div class="header grey-bg border-right border-left nav-font16 zIndex2">Quotas</div>
        @for (vendor of vendors; track vendor; let isLast = $last) {
          <div class="header grey-bg zIndex1" [class.border-right]="!isLast">{{vendor.partnerName}}
            @if (vendor.hasZeroQuota && projectQuota.limitCheck != 'tracking') {
              <span class="fal fa-triangle-exclamation warning-yellow ml-2 nav-font18" tooltip="Quotas with goals of '0' will result in Nav OQs" placement="bottom" container="body"></span>
            }
          </div>
        }
      </div>
      <div class="vendor-quotas-card second-row border-bottom zIndex2" [style.width]="'min-content'" [style.grid-template-columns]="vendorCardGridTemplate">
        <div class="header grey-bg border-right border-left zIndex2"></div>
        @for (vendor of vendors; track vendor; let isLastVendor = $last) {
          <div class="header grey-bg zIndex1" [class.border-right]="!isLastVendor">
            <div class="vendor-goal">Goal</div>
            <div class="vendor-percentage">%</div>
          </div>
        }
      </div>
      <div class="vendor-quotas-card third-row zIndex2" [class.noVendors]="!vendors?.length > 0" [class.border-bottom]="projectQuota.quotaGroups.length > 0" [style.width]="'min-content'" [style.grid-template-columns]="vendorCardGridTemplate">
        <div class="header white-bg border-right border-left zIndex2">Allocation</div>
        @for (vendor of vendors; track vendor; let isLastVendor = $last) {
          <div class="header white-bg zIndex1" [class.border-right]="!isLastVendor">
            <div class="vendor-goal">
              <div class="inline-input d-flex justify-content-center">
                <div class="input-group" [class.pl-0]="vendor.partnerFullQuota > 9999">
                  <input #vendorLimitN
                    class="form-control form-control-nav nav-secondary font-weight-bold"
                    type="text"
                    [(ngModel)]="vendor.partnerFullQuota">
                  <div class="input-group-append nav-secondary">n</div>
                </div>
              </div>
            </div>
            <div class="vendor-percentage">
              <div class="inline-input d-flex justify-content-center">
                <div class="input-group"
                  [class.pl-0]="vendor.totalFullQuotaPercent > 9999">
                  <input #vendorLimitPercent
                    class="form-control form-control-nav nav-secondary font-weight-bold"
                    type="text"
                    [(ngModel)]="vendor.totalFullQuotaPercent">
                  <div class="input-group-append nav-secondary">%</div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      @for (group of projectQuota.quotaGroups; track group; let isLastQuotaGroup = $last; let isFirstQuotaGroup = $first) {
        @if (!group.isDeleted) {
          <div class="vendor-quotas-card quota-name-row border-bottom border-top" [class.border-top]="!isFirstQuotaGroup" [style.width]="'min-content'" [style.grid-template-columns]="vendorCardGridTemplate">
            <div class="header grey-bg border-right border-left zIndex1">{{group.name}}
            </div>
            @for (vendor of vendors; track vendor; let isLast = $last) {
              <div class="header grey-bg" [class.border-right]="!isLast"></div>
            }
          </div>
          @for (quota of group.quotas; track quota; let isLastQuota = $last) {
            @if (!quota.isDeleted && !quota.hideQuota) {
              <div class="vendor-quotas-card" [class.border-bottom-half]="!isLastQuota" [style.width]="'min-content'" [style.grid-template-columns]="vendorCardGridTemplate">
                <div class="quota white-bg border-right-half border-left zIndex1">
                  <ng-template *ngTemplateOutlet="quotaValueText; context : { $implicit: quota, group: group }" ></ng-template>
                </div>
  
                @for (vendor of vendors; track vendor; let isLast = $last) {
                  @for (quotaVendor of quota.vendorLimits; track quotaVendor) {
                    @if (quotaVendor.vendorId == vendor.partnerId) {
                      <div class="quota white-bg" [class.border-right-half]="!isLast">
                        <div class="vendor-goal">
                          <div class="inline-input">
                            <div class="input-group" (click)="vendorLimitN.focus()" 
                              [class.warning-yellow-border]="!isVendorOrClient && quotaVendor.limitN == 0 && projectQuota.limitCheck != 'tracking'">
                              <input #vendorLimitN
                                class="form-control form-control-nav"
                                type="text"
                                mask="9999"
                                [specialCharacters]="[]"
                                (keyup.enter)="vendorLimitN.blur()"
                                (blur)="vendorLimitChange(quota, quotaVendor, 'n', $event)"
                                [(ngModel)]="quotaVendor.limitN">
                              <div class="input-group-append grey6">n</div>
                            </div>
                          </div>
                        </div>
                        <div class="vendor-percentage">
                          <div class="inline-input">
                            <div class="input-group" (click)="vendorLimitPercent.focus()" 
                              [class.warning-yellow-border]="!isVendorOrClient && quotaVendor.limitPercent == 0 && projectQuota.limitCheck != 'tracking'">
                              <input #vendorLimitPercent
                                class="form-control form-control-nav"
                                type="text"
                                mask="999"
                                [specialCharacters]="[]"
                                (keyup.enter)="vendorLimitPercent.blur()"
                                (blur)="vendorLimitChange(quota, quotaVendor, '%', $event)"
                                [(ngModel)]="quotaVendor.limitPercent">
                              <div class="input-group-append grey6">%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  }
                }
              </div>
            }
          }
        }
      }
    </div>
  }
</div>

<ng-template #quotaCard let-group>
  <div class="card p-4">
    <div class="card-header nav-font18 grey6 font-weight-bold nav-secondary p-0 mb-3 pb-1">
      <div class="quota-row align-items-center">
        @if (group.questionId.length <= 36) {
          <i (click)="quotaCantBeDeleted ? null : removeQuotaGroup(group.id)" [class.disabledBtn]="quotaCantBeDeleted"
            class="fak fa-trash px-1 pt-2px mr-2 nav-error nav-font18 font-weight-normal icon cursor-pointer"
            containerClass="disabled-btn-tooltip"
            [tooltip]="quotaCantBeDeleted ? 'Quota group cannot be deleted after live traffic has started' : null" placement="bottom">
          </i>
        }
        @else {
          @if (group.loadingUndoNest != true) {
            <i (click)="quotaCantBeChanged ? null : undoNest(group)" [class.disabledBtn]="quotaCantBeChanged"
            containerClass="disabled-btn-tooltip" [tooltip]="quotaCantBeChanged ? 'Project must be in Paused status in order to make changes' : 'Undo nest'"
            placement="bottom" class="fa-sharp fa-light fa-object-ungroup pt-1 mr-2 nav-error nav-font18 icon cursor-pointer"></i>
          }
          @else {
            <span class="loading"></span>
          }

        }
        <span></span>
        <span class="quota-name-col">{{group.name}}
          @if (group.warningTooltip) {
            <ng-template #warningTextTemplate><div [innerHtml]="group.warningTooltip"></div></ng-template>
            <span class="fal fa-triangle-exclamation warning-yellow ml-2" [tooltip]="warningTextTemplate" placement="bottom" container="body"></span>
          }
        </span>
        <span></span>
        <span class="goal-col text-center">Goal</span>
        <span></span>
        <span class="percent-col text-center">%</span>
      </div>
    </div>  
    <div class="card-body p-0">
      @for (quota of group.quotas; track quota; let i = $index) {
        @if (quota.isDeleted == false && !quota.hideQuota) {
          <ng-container class="">
            <div class="quota-row align-items-center mb-3">
              <i class="far fa-times nav-font18 icon p-1 cursor-pointer grey4" [style.visibility]="group.questionId.length > 36 ? 'hidden' : 'visible'" 
                (click)="quotaCantBeDeleted && (newQuota?.id !== quota.id || group.name === 'Income') ? null : removeQuota(group, quota.id)"
                [class.disabledBtn]="quotaCantBeDeleted"
                containerClass="disabled-btn-tooltip"
                [tooltip]="quotaCantBeDeleted && (newQuota?.id !== quota.id || group.name === 'Income') ? 'Quota cannot be deleted after live traffic has started' : null" placement="bottom"></i>
              <span></span>
                @if (group.name != 'Age' && group.name != 'Income') {
                  <span class="grey5 nav-font14">
                    <ng-template *ngTemplateOutlet="quotaValueText; context : { $implicit: quota, group: group }" ></ng-template>
                  </span>
                }
                @else {
                  @if (group.name == 'Income') {
                    @if (quota.targetGroup.minHHI > -1) {
                      <div class="d-flex nav-font14">
                        <div dropdown dropdownToggle #hhiDropdownMin="bs-dropdown" tabindex="-1" class="position-relative nav-font14 grey5 hhiInput" 
                          [class.clickable]="quota.id ===  newQuota?.id" [class.open]="hhiDropdownMin.isOpen" type="button" [class.warning-yellow-border]="quota.valueOverlap">
                            {{quota.targetGroup.minHHI | currency :'USD':'symbol':'1.0-1' }}
                            <i class="fas grey3 pr-2 pl-1 nav-font12 float-right" [class.fa-chevron-up]="hhiDropdownMin.isOpen" [class.fa-chevron-down]="!hhiDropdownMin.isOpen"></i>
                          <div *dropdownMenu class="dropdown-menu dropdown-menu-left nav-font14 grey5 p-0" role="menu" aria-labelledby="button-basic">
                            <div class="dropdown-item text-truncate my-1 px-2" (click)="quota.targetGroup.minHHI != income ? minMaxChanged(quota, 'min', 'income', newQuota?.id === quota.id, 0) : null">
                              {{0 | currency :'USD':'symbol':'1.0-0' }}
                            </div>
                            @for (income of hhiList; track income) {
                              <div class="dropdown-item text-truncate my-1 px-2" (click)="quota.targetGroup.minHHI != income ? minMaxChanged(quota, 'min', 'income', newQuota?.id === quota.id, income) : null">
                                {{income | currency :'USD':'symbol':'1.0-1' }}
                              </div>
                            }
                          </div>
                        </div>
                    
                        <div class="nav-font14 mr-2 ml-2 mt-1 d-inline"> to </div>
                        <div dropdown dropdownToggle #hhiDropdownMax="bs-dropdown" tabindex="-1" class="position-relative nav-font14 grey5 hhiInput" 
                          [class.clickable]="quota.id ===  newQuota?.id" [class.open]="hhiDropdownMax.isOpen" type="button" [class.warning-yellow-border]="quota.valueOverlap">
                          {{quota.targetGroup.maxHHI > hhiMax ? 'and above' : quota.targetGroup.maxHHI | currency :'USD':'symbol':'1.0-1' }}
                          <i class="fas grey3 pr-2 pl-1 nav-font12 float-right" [class.fa-chevron-up]="hhiDropdownMax.isOpen" [class.fa-chevron-down]="!hhiDropdownMax.isOpen"></i>
                        <div *dropdownMenu class="dropdown-menu dropdown-menu-left nav-font14 grey5 p-0" role="menu" aria-labelledby="button-basic">
                          @for (income of hhiList; track income) {
                            @if (quota.targetGroup.minHHI < income-1) {
                              <div class="dropdown-item text-truncate my-1 px-2" (click)="quota.targetGroup.minHHI != income ? minMaxChanged(quota, 'max', 'income', newQuota?.id === quota.id, income-1) : null">
                                {{income-1 | currency :'USD':'symbol':'1.0-1' }}
                              </div>   
                            }
                          }
                          <div class="dropdown-item text-truncate my-1 px-2" (click)="quota.targetGroup.minHHI != income ? minMaxChanged(quota, 'max', 'income', newQuota?.id === quota.id, hhiMax+1) : null">
                            and above
                          </div>
                        </div>
                      </div>
                    </div>
                    }
                    @if (quota.targetGroup.minHHI == -1) {
                      <div class="d-inline font-italic nav-font14 grey5">
                        Prefer not to answer
                      </div>
                    }
                  }
                  @else if (group.name == 'Age') {
                    <div class="d-flex">
                      <input #minAgeInput type="text" [specialCharacters]="[]" [class.warning-yellow-border]="quota.valueOverlap" (keyup.enter)="minAgeInput.blur()" class="ageInput form-control form-control-nav form-control-sm" 
                        [value]="quota.targetGroup.minAge" [class.clickable]="quota.id ===  newQuota?.id" 
                        (blur)="quota.targetGroup.minAge != minAgeInput.value ? minMaxChanged(quota, 'min', 'age', newQuota?.id === quota.id, minAgeInput.value, minAgeInput) : null">
                      <div class="nav-font14 mr-2 ml-2 mt-1 d-inline"> to </div>
                      <input #maxAgeInput type="text"  [specialCharacters]="[]" [class.warning-yellow-border]="quota.valueOverlap" (keyup.enter)="maxAgeInput.blur()" class="ageInput form-control form-control-nav form-control-sm" 
                        [value]="quota.targetGroup.maxAge" [class.clickable]="quota.id ===  newQuota?.id"
                        (blur)="quota.targetGroup.maxAge != maxAgeInput.value ? minMaxChanged(quota, 'max', 'age', newQuota?.id === quota.id, maxAgeInput.value, maxAgeInput) : null">                     
                    </div>
                  }
                }
              <span></span>
              <!-- limit number -->
              <div class="d-flex justify-content-center">
                <div class="input-group goal-input" (click)="inputLimitN.focus()" [class.warning-yellow-border]="!isVendorOrClient && quota.limitN == 0 && projectQuota.limitCheck != 'tracking'">
                  <input #inputLimitN (keyup.enter)="inputLimitN.blur()" (blur)="quotaLimitChange(quota, 'n', $event)" type="text"
                    class="form-control form-control-nav nav-font14 text-left" [class.clickable]="quota.id ===  newQuota?.id"
                    oninput="this.value = this.value.replace(/[^0-9*.]/g, '').replace(/(\..*)\./g, '$1');"
                    [(ngModel)]="quota.limitN">
                    <div class="input-group-append nav-font14 grey6 px-1">n</div>
                </div>
              </div>
              <span></span>
              <!-- limit percentage -->
              <div class="d-flex justify-content-center">
                <div class="input-group percentage-input" (click)="inputLimitP.focus()" [class.warning-yellow-border]="!isVendorOrClient && quota.limitPercent == 0 && projectQuota.limitCheck != 'tracking'">
                  <input #inputLimitP (keyup.enter)="inputLimitP.blur()" (blur)="quotaLimitChange(quota, '%', $event)" type="text"
                    class="form-control form-control-nav nav-font14 text-left" [class.clickable]="quota.id ===  newQuota?.id"
                    oninput="this.value = this.value.replace(/[^0-9*.]/g, '').replace(/(\..*)\./g, '$1');"
                    [(ngModel)]="quota.limitPercent">
                    <div class="input-group-append nav-font14 grey6 px-1">%</div>
                </div>
              </div>
            </div>
          </ng-container>
        }
      }
      <!-- if not nested -->
      @if (group.questionId.length <= 36 && !isVendorOrClient) {
        <!-- btn to add back deleted quotas -->
        <div class="d-flex align-items-center mt-3">
          <div class="d-flex align-items-center nav-font14 text-nowrap ml-4">
            @if (questions[group.questionId].type == 'HouseholdIncome' || questions[group.questionId].type == 'Age') {
              <span tabindex="-1" class="font-weight-bold nav-accent-blue p-0 pl-2 pr-2 d-flex align-items-center" 
                [ngClass]="newQuota?.groupId === group.id || quotaCantBeChanged ? 'clickable disabledBtn' : ''" type="button" 
                (click)="newQuota?.groupId !== group.id && !quotaCantBeChanged ? addBlankQuota(group, questions[group.questionId].type) : null"
                [tooltip]="quotaCantBeChanged ? 'Project must be in Paused status in order to make changes' : null" placement="bottom"
                containerClass="disabled-btn-tooltip">
                <i class="far fa-plus icon nav-font14 mr-2" [ngClass]="newQuota?.groupId === group.id ? 'clickable disabledBtn' : ''"></i>Add
              </span>
            }
            @if (group.deletedOptions?.length > 0 && group.name !== 'Income' && group.name !== 'Age') {
              <span dropdown dropdownToggle #addBackDropdown="bs-dropdown" tabindex="-1" 
                class="font-weight-bold nav-accent-blue p-0 d-flex align-items-center position-relative addBackBtn"
                [ngClass]="newQuota?.groupId === group.id || quotaCantBeChanged ? 'clickable disabledBtn' : ''" type="button"
                [tooltip]="quotaCantBeChanged ? 'Project must be in Paused status in order to make changes' : null" placement="bottom"
                containerClass="disabled-btn-tooltip">
                <i class="far fa-plus icon nav-font14 mr-2"></i>Add
                <div *dropdownMenu class="dropdown-menu dropdown-menu-left nav-font14" role="menu" aria-labelledby="button-basic">
                  @for (option of group.deletedOptions; track option; let isLast = $last) {
                    <div class="dropdown-item text-truncate my-1 px-3" (click)="option.name === 'pnta' ? addBackPNTA(group) : addBackQuota(group, option)"
                      [class.font-italic]="option.name === 'pnta'">{{option.name === 'pnta' ? 'Prefer not to answer' : option.name}}
                    </div>
                    @if (!isLast) {
                      <div class="separator px-1"><hr class="my-0"></div>
                    }
                  }
                </div>
              </span>
            }
            @if (group.name == 'Income' && group.deletedOptions.length > 0) {
              <span class="font-weight-bold nav-accent-blue cursor-pointer p-0 clickable" [ngClass]="newQuota?.groupId === group.id || quotaCantBeChanged ? 'clickable disabledBtn' : ''"  
                (click)="newQuota?.groupId !== group.id && !quotaCantBeChanged ? addBackPNTA(group) : null" [tooltip]="quotaCantBeChanged ? 'Project must be in Paused status in order to make changes' : null" 
                placement="bottom" containerClass="disabled-btn-tooltip">
                <i class="far fa-plus icon nav-font14 mr-2"  [ngClass]="newQuota?.groupId === group.id ? 'clickable disabledBtn' : ''" ></i>Prefer not to answer
              </span>
            }
            @if (group.deletedOptions?.length > 0 || group.name === 'Income' || group.name === 'Age') {
              <div class="separator ml-3"></div>
            }
            @if (group.deletedOptions?.length == 0) {
              <div class="separator ml-4"></div>
            }
          </div>
        </div>
      }
    
      <!-- last row: adding quotas and totals -->
      <div class="quota-row mt-1 nav-secondary nav-font16 font-weight-bold">
        <!-- adding quotas -->
        <span></span><span></span>
        <div class="d-flex justify-content-end">
          Total
        </div>
        <span></span>
        <div class="d-flex justify-content-center">
          <div class="goal-input text-right">
            {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].n : ''}} n
          </div>
        </div>
        <span></span>
        <div class="d-flex justify-content-center">
          <div class="percentage-input text-right">
            {{quotaGroupTotals[group.id] ? quotaGroupTotals[group.id].percent : ''}} %
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #quotaConfig >
  @if (quotaConfigEditDetails; as config) {
    <div class="card py-4 pl-4 pr-3">
    <div class="card-header nav-font24 nav-secondary font-weight-bold p-0 mb-4" [ngClass]="config.mode == 'add' && config.step == 1 ? 'pb-2' : ''">
      <div class="d-flex align-items-center position-relative">
        <span class="">{{config.mode === 'add' ? 'Add Quotas' : (config.mode === 'edit' ? 'Quota Settings' : 'Nest Quotas')}}</span>
        <i [class.disabledBtn]="loadingSave || loadingNest" class="fa fa-times nav-font20 grey4 font-weight-normal cursor-pointer position-absolute" (click)="!loadingSave && !loadingNest ? openModalRefQuotaConfig.hide() : null"></i>
      </div>
    </div>
    <div class="card-body nav-font16 font-weight-bold grey5 p-0">
      @if (config.mode == 'add' && config.step == 1) {
        <div class="mb-4">Select the demographic quotas needed for your survey, then click {{config.quotasNotAdded.length == 6 ? "'Next'" : "'Add'"}}
          @if (config.quotasNotAdded.length == 6) {
            <span class="grey3 font-weight-normal ml-2">(step 1 of 2)</span>
          }
        </div>
        <div class="nav-font14 grey5 font-weight-normal ml-1">
          <div class="or-checkbox d-flex p-1 mb-2">
            <input type="checkbox" name="quotas-select-all" id="quotas-select-all" [checked]="quotaConfigEditDetails.selectAllQuotas" (change)="quotaConfigEditDetails.selectAllQuotas = $event.target.checked; selectAllQuotasInConfig($event)" >
            <label for="quotas-select-all" class="select-all"></label>
            <span class="ml-4">Select all</span>
          </div>
          @for (quota of config.quotasNotAdded; track quota.id) {
            <div class="or-checkbox d-flex p-1 mb-2">
              <input type="checkbox" name="quota-{{quota.value}}" id="quota-{{quota.value}}"
                [checked]="quota.selected" (change)="quota.selected = $event.target.checked; quotaSelected()">
                <label for="quota-{{quota.value}}"></label>
                <span class="ml-4">{{quota.value == 'HouseholdIncome' ? 'Income' : quota.value}}</span>
            </div>
          }
        </div>
      }
      @else if (config.mode == 'nest') {
        <div class="mb-3">Select up to three quotas to nest together. Once selected, click 'Nest'.
        </div>
        <div class="font-weight-normal nav-font14 mb-4 pb-2">You can use drag and drop to change the order of the quotas. Once nested, the top quota in the list will appear first in the card and table.</div>
        <div class="nav-font14 grey5 font-weight-normal ml-1 nestList"
          [dndDropzone]="['quota']"
          (dndDrop)="onDrop($event)">
          <div class="d-flex align-items-center mb-3" dndPlaceholderRef>
            <i class="fas fa-grip-lines opacity-20 pl-3"></i>
          </div>
          @for (quota of config.quotasAdded; track quota.id) {
            <div class="d-flex align-items-center mb-3"
                [dndDraggable]="quota"
                dndType="quota"
                dndEffectAllowed="move">
              <i class="fak fa-drag grey4 nav-font24 font-weight-normal clickable mr-1" dndHandle></i>
              <div class="or-checkbox d-flex p-1">
                <input type="checkbox" name="quota-{{quota.value}}" id="quota-{{quota.value}}"
                  [disabled]="!quota.selected && config.atLeastThreeSelected"
                  [checked]="quota.selected" (change)="quota.selected = $event.target.checked; quotaSelectedForNest()">
                  <label for="quota-{{quota.value}}"></label>
                  <span class="ml-4 nav-font16">{{quota.value == 'HouseholdIncome' ? 'Income' : quota.value}}</span>
              </div>
            </div>
          }
        </div>
      }
      @if (config.mode == 'edit' || config.step == 2) {
        <div>Select how you want to control your quotas, then click '{{config.mode == 'add' ? 'Add' : 'Save'}}'
          @if (config.step == 2) {
            <span class="grey3 font-weight-normal ml-2">(step 2 of 2)</span>
          }
        </div>
        <div class="font-weight-normal mt-3 mb-4 pb-2">
          @if (config.mode == 'add') {
            The control options you select will apply to all quotas in this project. You may later edit your control options in quota settings.
          }
          @if (config.mode == 'edit') {
            The control options you select will apply to all quotas in this project.
          }
        </div>
        <div class="nav-font14 grey5">
          <div class="row mb-4 pb-3">
            <div class="col-4 font-weight-bold pr-0">Base quota goals on</div>
            <div class="col font-weight-normal">
              <div class="radio-group">
                <div class="or-radio mb-4">
                  <input type="radio" name="limitModeComplete" id="limitModeComplete" [checked]="config.limitMode == 'complete'" (change)="config.limitMode = 'complete'">
                  <label for="limitModeComplete"></label>
                  Completes
                  <i class="far fa-light fa-info-circle nav-font14 grey4 cursor-default ml-2" placement="bottom" tooltip="This will set the number of completes as the goal/limit that return from the survey"></i>
                </div>
                <div class="or-radio">
                  <input type="radio" name="limitModeStart" id="limitModeStart" [checked]="config.limitMode == 'start'" (change)="config.limitMode = 'start'">
                  <label for="limitModeStart"></label>
                  Starts
                  <i class="far fa-light fa-info-circle nav-font14 grey4 cursor-default ml-2" placement="bottom" tooltip="This will set the number of starts as the goal/limit post-CleanID for live traffic in the survey"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4 pb-3">
            <div class="col-4 font-weight-bold pr-0">Distribute quotas by using</div>
            <div class="col font-weight-normal">
              <div class="radio-group">
                <div class="or-radio mb-4">
                  <input type="radio" name="distributeByCensus" id="distributeByCensus" [checked]="config.distributeBy == 'census'" (change)="config.distributeBy = 'census'">
                  <label for="distributeByCensus"></label>
                  Census
                </div>
                <div class="or-radio mb-4">
                  <input type="radio" name="distibuteByCustom" id="distibuteByCustom" [checked]="config.distributeBy == 'custom'" (change)="config.distributeBy = 'custom'">
                  <label for="distibuteByCustom"></label>
                  Custom
                </div>
                <div class="or-radio">
                  <input type="radio" name="distibuteByEqual" id="distibuteByEqual" [checked]="config.distributeBy == 'equal'" (change)="config.distributeBy = 'equal'">
                  <label for="distibuteByEqual"></label>
                  Equal distribution
                </div>
              </div>
            </div>
          </div>
          <div class="row d-flex align-items-center mb-4 pb-3">
            <div class="col-4 font-weight-bold pr-0">Utilize vendor level quotas</div>
            <div class="col font-weight-normal">
              <or-toggle-button  class="light" size="small" [isChecked]="config.enableVendorLimits" (changed)="config.enableVendorLimits=$event"></or-toggle-button>
            </div>
          </div>
          <div class="row mb-4 pb-3">
            <div class="col-4 font-weight-bold pr-0">Select your quota control</div>
            <div class="col font-weight-normal">
              <div class="radio-group">
                <div class="or-radio mb-4">
                  <input type="radio" name="limitCheckTracking" id="limitCheckTracking" [checked]="config.limitCheck == 'tracking'" (change)="config.limitCheck = 'tracking'; config.applyFlexibility = false; config.distributionFlexibility = null">
                  <label for="limitCheckTracking"></label>
                  Tracking only
                  <i class="far fa-light fa-info-circle nav-font14 grey4 cursor-default ml-2" placement="bottom" tooltip="Allow traffic to continue after a goal is met"></i>
                </div>
                <div class="or-radio">
                  <input type="radio" name="limitCheckEnforced" id="limitCheckEnforced" [checked]="config.limitCheck == 'enforced'" (change)="config.limitCheck = 'enforced'">
                  <label for="limitCheckEnforced"></label>
                  Enforce goals
                  <i class="far fa-light fa-info-circle nav-font14 grey4 cursor-default ml-2" placement="bottom" tooltip="Pause traffic for the quota selection once the limit is reached"></i>
                </div>
              </div>
            </div>
          </div>
          <div [style.visibility]="config.limitCheck == 'enforced' ? 'visible' : 'hidden'" class="row d-flex align-items-center mb-4 pb-3 height25">
            <div class="col-4 font-weight-bold pr-0">Apply flexibility to distibution</div>
            <div class="col-1 font-weight-normal">
              @if (config.limitCheck == 'enforced') {
                <or-toggle-button class="light" size="small" [isChecked]="config.applyFlexibility"
                (changed)="config.applyFlexibility=$event; config.distributionFlexibility = $event == false ? null : 5"></or-toggle-button>
              }
            </div>
            @if (config.applyFlexibility) {
              <div class="col font-weight-normal d-flex align-items-center text-nowrap pl-1 ml-3">
                Apply limit flexibility by
                <div class="input-group ml-2" (click)="distributionFlexibility.focus()">
                  <input #distributionFlexibility
                    class="form-control form-control-nav nav-font14 grey5"
                    type="text"
                    mask="99"
                    [specialCharacters]="[]"
                    [(ngModel)]="config.distributionFlexibility">
                    <div class="input-group-append grey4">%</div>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
      <div class="card-footer p-0">
        <div class="float-right d-flex">
          <button [class.disabledBtn]="loadingSave || loadingNest" type="button" class="btn nav-btn-primary shadow-none font-weight-bold py-1 mx-2" (click)="!loadingSave && !loadingNest ? openModalRefQuotaConfig.hide() : null">Cancel</button>
          @if (loadingSave || loadingNest) {
            <div class="pt-2">
              <span class="loading mr-2"></span>
            </div>
          }
          <button type="button" class="btn nav-btn-accent shadow-none font-weight-bold py-1"
            [class.disabledBtn]="loadingSave || loadingNest || (config.mode == 'add' && !config.atLeastOneQuotaSelected) || (config.mode == 'nest' && !config.atLeastTwoQuotasSelected)" (click)="(config.mode !== 'add' || config.atLeastOneQuotaSelected) ? nextInQuotaConfigModal() : null">
            @if (config.mode == 'add' && config.step == 1 && config.quotasAdded.length == 0) {
              Next
            }
            @else if (config.mode == 'add' && (config.step == 2 || config.quotasAdded.length > 0)) {
              Add
            }
            @else if (config.mode == 'nest') {
              Nest
            }
            @else {
              Save
            }
          </button>
        </div>
      </div>
    </div>
  }
</ng-template>

<ng-template #quotaValueText let-quota let-group="group">
  @for (name of group.nameArray; track name; let isLast = $last; let isFirst = $first) {
    @if (isFirst || !quota.isPNTA) {
      @if (quota.targetGroup[name.toLowerCase()]; as value) {
        <span [class.font-italic]="value === 'pnta'">{{value === 'pnta' ? 'Prefer not to answer' : value}}</span>
      }
      @else {
        @if (name == 'Age') {
          @if (quota.targetGroup.minAge == -1) {
            <span class="font-italic">Prefer not to answer</span>
          }
          @else {
            <span>{{quota.targetGroup.minAge}} to {{quota.targetGroup.maxAge}}</span>
          }
        }
        @else if (name == 'HouseholdIncome') {
          @if (quota.targetGroup.minHHI == -1) {
            <span class="font-italic">Prefer not to answer</span>
          }
          @else {
            <span>{{quota.targetGroup.minHHI | currency :'USD':'symbol':'1.0-0'}} 
              @if (quota.targetGroup.maxHHI <= hhiMax) {
                to {{quota.targetGroup.maxHHI | currency :'USD':'symbol':'1.0-0'}}
              }
              @else {
                and above
              }
            </span>
          }
        }
      }
    }
    @if (!quota.isPNTA && !isLast && group.nameArray.length > 1) {
      <span>, </span>
    }
  }
</ng-template>

<ng-template #modal>
  <div class="modal-header">
    <h2 class="nav-secondary font-weight-bold nav-font18 mb-0 ml-1 pt-1">{{modalData.title}}</h2>
    <i class="far fa-times nav-font18 icon cursor-pointer grey4 pb-1 pr-1"(click)="closeModal();"></i>
  </div>
  <div class="modal-body">
    @if (modalType==='templateClone') {
      <div class="px-1">Search for a quota to clone from existing projects:</div>
      <div class="pr-0  mr-3 mb-0 pb-0 mt-2 templateSearch">
        <ng-container>
          <or-type-ahead  [labelField]="'name'" [multi]="false" [valueField]="'value'" [showLoading]="false" class=""
            [dataCallback]="getProjectList"
            cleanAfterSelect="true"
            placeholder="Search by ID, Name or Client"
            (valueChange)="updateProject($event)" >
          </or-type-ahead>
          <div class="mt-5 mb-3">
            @if (projectList.length > 0) {
              <or-list class="quotaTemplateList" [items]="projectList" [layout]="quotaTemplates" [actions]="actions" (action)="handleAction($event)" [enableExport]="false"></or-list>
            }
          </div>
        </ng-container>
      </div>
    }
    @if (modalType==='templateImport') {
      <p class="grey5 mb-5">Import quota from a .xlsx or .csv file. This will override any existing quota setup on this project.</p>
      <div class="pr-0 mr-3 mb-0 pb-0 mt-2 d-flex justify-content-center">
        <div class="col-md-6 col-lg-6 pr-0">
          <div class="nav-font16 grey6 mb-4"><span class="font-weight-bold grey5">Step 1:&nbsp;</span> Select your file</div>
          <div class="ml-3">
            <or-upload
            mainMsg="Select a CSV file to upload"
            smallMsg="or drag and drop the file here to begin"
            [includeProgressBar]=true
            acceptTypes=".csv,.xlsx"
            [includeFileName]="true"
            [instanceId]="auth.getUser().instanceId"
            directToS3=true
            [S3Folder]="s3buckPath"
            publicBucket=true
            (uploadCompleted)="importFileUploaded($event)"
            id="img" ></or-upload>
          </div>
        </div>
        <div class="col-lg-1"></div>
        <div class="col-md-6 col-lg-5 nav-font14 grey5 p-0">
          <div class="grey6 mb-4 nav-font16" ><span class="font-weight-bold grey5">Step 2:&nbsp;</span>  Select allocation type to apply</div>
          <div class="radio-group ml-3 mt-5">
            <div class="or-radio align-items-center mb-3">
              <input type="radio" name="fileAllocationType" id="fileAllocationPercent" (change)="importFileAllocationType = 'percent'" [checked]="importFileAllocationType == 'percent'">
              <label for="fileAllocationPercent"></label>
              Percentage
              <i class="far fa-light fa-info-circle nav-font14 grey4 cursor-default ml-2" placement="bottom" tooltip="Select this option if the values in the file are percentages"></i>
            </div>
            <div class="or-radio align-items-center">
              <input type="radio" name="fileAllocationType" id="fileAllocationActual" (change)="importFileAllocationType = 'number'" [checked]="importFileAllocationType == 'number'">
              <label for="fileAllocationActual"></label>
              Actual
              <i class="far fa-light fa-info-circle nav-font14 grey4 cursor-default ml-2" placement="bottom" tooltip="Select this option if the values in the file are actual numbers"></i>
            </div>
          </div>
        </div>
      </div>
    }
    @if (modalType==='confirm-add') {
      <p class="grey5">{{modalData.message}}</p>
        <div class="pr-0 mr-3 mb-0 pb-0 mt-2 d-flex justify-content-center">
      </div>
    }
  </div>
  @if (modalType === 'templateImport' || modalType === 'confirm-add') {
    <div class="modal-footer text-right">
      @if (modalType==='templateImport') {
        @if (loadingImport) {
          <span class="loading mx-2 mt-2"></span>
        }
        <button type="button" class="btn nav-btn-primary shadow-none font-weight-bold" (click)="openModalRef.hide(); importFileName = null">Cancel</button>
        <button type="button" class="btn nav-btn-accent shadow-none font-weight-bold" (click)="importQuotaFromFile()" [class.disabledBtn]="!importFileName || loadingImport">Import Quota</button>
      }
      @if (modalType==='confirm-add') {
        <button type="button" class="btn nav-btn-primary shadow-none font-weight-bold" (click)="closeModal();">Cancel</button>
        <button type="button" class="btn nav-btn-accent shadow-none font-weight-bold" (click)="closeModal('continue')">Continue</button>
      }
    </div>
  }
</ng-template>