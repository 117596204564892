
<div class="stp-grid" [class.open]="sidebarOpen">
  <div class="">
    <div class="sidebar sidebar-sticky shadow-sm top-0 st-font" [class.open]="sidebarOpen" [class.active]="selectedMenu !== ''">

      <!-- Sidebar toggle -->
      <ul class="nav">
        <li class="sidebar-slide" (click)="toggleMenu()">
          <a class="sidebar-slide-toggle" [ngClass]="{'arrow-right': !sidebarOpen}">
            <ng-container *ngIf="!sidebarOpen && !loading">
              <i class="fas fa-bars text-white text-white mr-1"></i>
              <i class="fak fa-xs fa-right-arrow text-white text-white"></i>
            </ng-container>
            <ng-container *ngIf="sidebarOpen && !loading">
              <i class="fak fa-left-arrow text-white text-white" *ngIf="sidebarOpen"></i>
              <i class="fas fa-bars text-white text-white"></i>
            </ng-container>
            <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg text-white mr-1"></i>
          </a>
        </li>
      </ul>

      <!-- Sidebar menu -->
      <ul class="menu m-0 p-0 d-flex justify-content-center">
        <li class="btn btn-outline-light text-center border-0" (click)="openSection('info')" [class.selected]="selectedMenu == 'info'">
          <i class="fak fa-document-info d-block"></i>
          <span class="small">Survey Info</span>
        </li>
        <li class="btn btn-outline-light text-center border-0" (click)="openSection('comments')"[class.selected]="selectedMenu == 'comments'">
          <i class="fak fa-messages d-block"></i>
          <span class="small">Comments</span>
        </li>
        <li class="btn btn-outline-light text-center border-0" (click)="openSection('activity')"[class.selected]="selectedMenu == 'activity'">
          <i class="fak fa-session-log d-block"></i>
          <span class="small">Session Log</span>
        </li>
      </ul>

      <ng-container *ngIf="selectedMenu !== ''">
        <div class="h-100 w-100">
          <div class="content">
            <survey-tester-info *ngIf="selectedMenu == 'info'" [settings]="settings" [surveyId]="surveyId" (callback)="callbackFromInfoComponent($event)" (emulate)="emulateDevice($event)"
              [settings]="{ client }">
            </survey-tester-info>
            <survey-tester-activity *ngIf="selectedMenu == 'activity'"
              [data]="{ surveyId, playMode,client }"
              (callback)="callbackFromActivityComponent($event)">
            </survey-tester-activity>
            <survey-tester-comments *ngIf="selectedMenu == 'comments'" (callback)="callbackFromCommentsComponent($event)"
              [allComments]="comments"
              [data]="{ surveyId, sessionId: userSessionId, sessionFilterId, sessionFilterName, questionId, answerId, surveyStatus, statusFilter, isLive, client }">
            </survey-tester-comments>
          </div>
          <div class="footer position-absolute sticky-bottom fixed-bottom">
            <hr class="px-3 nav-border3 mx-3">
            <ul class="nav nav-pills flex-column mb-auto">
              <li *ngIf="selectedMenu == 'comments'">
                <div class="row mx-0">
                  <div class="col">
                    <span class="nav-primary font-weight-bold">{{surveyInfo?.projects[0]?.projectCode}}</span>
                    <small class="nav-primary d-block">({{surveyInfo?.totalOpen}}) New comments</small>
                  </div>
                  <div class="col d-flex align-items-end justify-content-end">
                    <button type="button" class="btn btn-sm nav-btn-accent" (click)="addComment()" *ngIf="surveyInfo?.status != 'finalized'">Add Comment</button>
                  </div>
                </div>
              </li>
              <li *ngIf="selectedMenu == 'info'">
                <div class="row mx-0">
                  <div class="col">
                    <span class="nav-primary font-weight-bold">{{surveyInfo?.projects[0]?.projectCode}}</span>
                  </div>
                  <div class="col d-flex align-items-end justify-content-end">
                    <ng-template *ngTemplateOutlet="tplFinalize; context:{ $implicit: surveyInfo}"></ng-template>
                  </div>
                </div>
              </li>
              <li *ngIf="selectedMenu == 'activity'">
                <div class="row mx-0">
                  <div class="col">
                    <span class="nav-primary font-weight-bold">{{surveyInfo?.projects[0]?.projectCode}}</span>
                    <small class="nav-primary d-block">(5) Team members</small>
                  </div>
                  <div class="col d-flex align-items-end justify-content-end">
                    <button type="button" class="btn btn-sm nav-btn-accent" (click)="export()">
                      Export <i class="fak fa-export ml-1"></i>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="">
    <!-- 3rd party Survey iFrame -->
    <div class="survey-content h-100">
      <div class="position-relative w-100 pt-2 playmode" *ngIf="playMode">
        <button class="btn btn-sm nav-btn-error shadow-none mr-3 float-right" (click)="stopReplay()">Exit play mode</button>
      </div>
      <iframe #myIframe frameborder=0 height="100%" src=""></iframe>
    </div>
  </div>
</div>

<!-- Templates for a neater layout -->
<ng-template #tplFinalize let-surveyInfo>
  <ng-container *ngIf="surveyInfo?.status == 'open'">
    <span [tooltip]="!surveyInfo?.canFinalize ? 'Close all open and pending comments to finalize survey testing' : ''" container="body">
      <button
        type="button"
        class="btn btn-sm text-nowrap"
        [disabled]="!surveyInfo?.canFinalize"
        [ngClass]="{'nav-btn-accent': surveyInfo?.canFinalize, 'nav-btn-disabled': !surveyInfo?.canFinalize}"
        (click)="confirm('finalize')">
        Finalize survey testing
      </button>
    </span>
  </ng-container>
  <ng-container *ngIf="surveyInfo?.status == 'finalized'">
    <button
      type="button"
      class="btn btn-sm nav-btn-accent"
      (click)="confirm('reopen')">
      Reopen survey testing
    </button>
  </ng-container>
</ng-template>


<!-- Modal Add Comments -->
<ng-template #modal>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="header-grid w-100">
      <div class="d-flex justify-content-center align-content-center">
        <h3 class="nav-secondary mb-0 ml-5 font-weight-bold">Add Comment</h3>
      </div>
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row mb-2">
      <div class="col">
        <span class="nav-secondary font-weight-bold">Question</span>
      </div>
      <div class="col d-flex align-content-center">
        <input type="text" class="form-control" *ngIf="this.client === 'Other'"  id="questionId" [(ngModel)]="newComment.questionId">

        <select class="form-control form-control-sm form-control-st" *ngIf="this.client !== 'Other'" [(ngModel)]="newComment.questionId" (change)="changeQuestion()">
          <option [ngValue]="''">N/A</option>
          <option *ngFor="let q of pageQuestions"[value]="q.questionId">{{q.questionCode | uppercase}}</option>
        </select>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <span class="nav-secondary font-weight-bold">Answer</span>
      </div>
      <div class="col d-flex align-content-center">
        <input type="text" class="form-control" *ngIf="this.client === 'Other'"  id="answerId" [(ngModel)]="newComment.answerId">
        <select class="form-control form-control-sm form-control-st" *ngIf="this.client !== 'Other'" [(ngModel)]="newComment.answerId" [disabled]="!newComment.questionId">
          <option [ngValue]="''">N/A</option>
          <option *ngFor="let ans of answers" [value]="ans.answerId">
            {{ ans.answerText.length > 15 ? (ans.answerText | slice:0:15) + '...' : ans.answerText }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <span class="nav-secondary font-weight-bold">Comment Type <span style="color: red;">*</span></span>
      </div>
      <div class="col d-flex align-content-center">
        <select class="form-control form-control-sm form-control-st" [(ngModel)]="newComment.commentType">
          <option [ngValue]="''">-Select-</option>
          <option value="client-change">Client change</option>
          <option value="program-error">Program error</option>
          <option value="other">Other</option>
        </select>
      </div>
    </div>
    <div class="col mt-3 px-0">
      <div class="ckeditor position-relative">
        <or-wysiwyg (messageEvent)="wysiwygMessage($event)" (messageCount)="getCommentCount($event)" [config]="editorConfig"></or-wysiwyg>
      </div>
    </div>
    <div class="col mt-3 px-0">
      <div class="ckeditor position-relative">
        <h6 class="grey5">
          <span>{{commentLength}}</span> / <span style="font-size: larger; font-weight: bolder;">∞</span></h6>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100" >
      <div class="col text-right pr-0" *ngIf="surveyInfo?.status != 'finalized'">
        <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">Cancel</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="saveComment()">Add Comment</button>
      </div>
      <div class="col" *ngIf="surveyInfo?.status == 'finalized'">
        <span class="badge badge-pill badge-info mr-1 px-1">This survey is finalized</span>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #modalConfirmFinalize>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h3 class="font-weight-bold nav-secondary">Are you sure you want to finalize the survey testing for {{surveyInfo?.projects[0].projectCode}}?</h3>
    <h5 class="grey5 text-center d-block my-3">
      <i class="fak fa-info grey4" (click)="closeModal()"></i>
      This action cannot be undone and will notify all project stakeholders.
    </h5>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100" >
      <div class="col text-right pr-0">
        <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">Cancel</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="finalize()">Finalize survey</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalConfirmReopen>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body pt-0">
    <h3 class="font-weight-bold nav-secondary">Are you sure you want to reopen the survey testing for {{surveyInfo?.projects[0].projectCode}}?</h3>
    <h5 class="grey5 text-center d-block my-3">
      <i class="fak fa-info grey4 fa-md" (click)="closeModal()"></i>
      This action cannot be undone and will notify all project stakeholders.
    </h5>
    <h5 class="nav-error d-block" *ngIf="isLive">
      <i class="fak fa-warning fa-md" (click)="openModalRef.hide();"></i>
      This testing session is occurring while you are in live mode. The testing data may end up in the live dataset.
    </h5>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100" >
      <div class="col text-right pr-0">
        <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">Cancel</button>
        <button class="btn btn-sm nav-btn-accent shadow-none" (click)="reopen()">Reopen survey</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalDownloadExtension>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <ng-container *ngIf="installingExtension == false">
    <div class="modal-body pt-0">
      <h3 class="font-weight-bold nav-secondary">Download the Survey Testing Portal Extension</h3>
      <h5 class="grey5 d-block my-3">
        Optimize your surveys with our free Survey Testing Portal extension! Enjoy enhanced collaboration, survey path viewing, and quick testing insights.
        <span class="d-block mt-4 font-weight-bold">Click 'Download' or 'Continue without Extension' to get started.</span>
      </h5>
    </div>
    <div class="modal-footer">
      <div class="row d-flex w-100" >
        <div class="col text-right pr-0">
          <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="openModalRef.hide();">Continue without Extension</button>
          <button class="btn btn-sm nav-btn-accent shadow-none" (click)="getExtension()">Download (recommended)</button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="installingExtension == true">
    <div class="modal-body pt-0">
      <h3 class="font-weight-bold nav-secondary">Download the Survey Testing Portal Extension</h3>
      <h5 class="grey5 d-block my-3">
        After you have installed the extension refresh the page.
      </h5>
    </div>
    <div class="modal-footer">
      <div class="row d-flex w-100" >
        <div class="col text-right pr-0">
          <button class="btn btn-sm nav-btn-accent shadow-none" (click)="refreshPage()">Refresh</button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>


<ng-template #modalConfirmitRestore>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="w-100">
      <div class="d-flex justify-content-end align-content-center">
        <i class="fas fa-close grey4" (click)="confirmitDoNotRestore()"></i>
      </div>
    </div>
  </div>
    <div class="modal-body pt-0">
      <h3 class="font-weight-bold nav-secondary">Restore to last location</h3>
      <h5 class="grey5 d-block my-3">
        Would you like to restore to your last location in this survey?
      </h5>
    </div>
    <div class="modal-footer">
      <div class="row d-flex w-100" >
        <div class="col text-right pr-0">
          <button class="btn btn-sm st-btn-primary mx-1 shadow-none" (click)="confirmitDoNotRestore();">No</button>
          <button class="btn btn-sm nav-btn-accent shadow-none" (click)="confirmitRestore()">Yes</button>
        </div>
      </div>
    </div>
</ng-template>
