<div class="card quota-metrics-card" [class.not-fullscreen]="!fullscreen" [class.fullscreen]="fullscreen" [ngClass]="!projectQuota || projectQuota?.quotaGroups.length == 0 ? 'no-quota' : 'quota-exists'" [class.grey1-border]="fullscreen" [class.no-hover-shadow]="fullscreen">
  <div class="card-header pr-0 pt-3">
    <div class="row m-0 pr-3 d-flex align-items-center">
      @if (!fullscreen) {
        <div class="icon-container">
          <i class="fa-solid fa-bars-progress fa-rotate-270 grey4 mr-2 py-1"></i>
        </div>
        <h3 class="nav-secondary font-weight-bold nav-font16">Quota Summary</h3>   
      }
      @else {
        <div class="d-flex align-items-center cursor-pointer grey4 nav-font16 mb-2" (click)="bsModalRef.hide()">
          <div class="px-2 mr-1">
            <i class="fas fa-chevron-left nav-font16"></i>
          </div>
          Back
        </div>
      }
      <div class="ml-auto">
        <div class="d-flex align-items-center">
          @if (fullscreen) {
            @if (projectQuota?.quotaGroups.length > 0) {
              <div class="settings-summary d-flex grey6 p-2">
                <span class="font-weight-bold mr-4 pr-2">Settings</span>
                <ul class="d-flex mb-0 p-0">
                  <li class="mr-4 pr-2"><span class="list-item-text">Goals based on {{projectQuota.limitMode+'s'}}</span></li>
                  <li class="mr-4 pr-2"><span class="list-item-text">{{projectQuota.distributeBy === 'census' ? 'Distributed by census' : (projectQuota.distributeBy === 'equal' ? 'Equal distribution' : 'Custom distribution')}}</span></li>
                  <li class="" [class.mr-4.pr-2]="projectQuota.limitCheck !== 'tracking' && projectQuota.distributionFlexibility">
                    <span class="list-item-text">{{projectQuota.limitCheck === 'tracking' ? 'Tracking only' : 'Enforce goals'}}</span>
                  </li>
                  @if (projectQuota.limitCheck !== 'tracking' && projectQuota.distributionFlexibility) {
                    <li class=""><span class="list-item-text">Flexibility up to {{projectQuota.distributionFlexibility}}%</span></li>
                  }
                </ul>
              </div>
              <div class="vertical-separator mx-3"></div>
            }
            @if (source != 'quota-setup') {
              <div class="btn nav-btn-primary shadow-none px-2 d-flex align-items-center" (click)="openQuotasSetup()">
                <i class="fak fa-open nav-font20 dark-grey mr-2"></i>
                <span class="nav-font14 font-weight-bold">Open in Quotas</span>
              </div>
              <div class="vertical-separator mx-3"></div>
            }
          }
          <div>
            <i class="fas grey3 cursor-pointer font-weight-normal nav-font16" 
              [ngClass]="fullscreen ? 'fas fa-arrows-minimize' : 'fa-arrows-maximize'" 
              (click)="fullscreen ? bsModalRef.hide() : openInModal()">
            </i>
          </div>
        </div>
      </div>
    </div>
    @if (!fullscreen && projectQuota?.quotaGroups.length > 0) {
      <div class="grey4 nav-font12 mt-2">Percentages are based on the project's pacing and timeline</div>
    }
  </div>
  <div class="card-body" [ngClass]="fullscreen ? 'pt-0 pb-4' : 'pt-1 pl-2 pr-0'" [class.highcharts-container]="!fullscreen && projectQuota?.quotaGroups.length > 0">
    @if (!fullscreen) {
      @if (!projectQuota || projectQuota?.quotaGroups.length == 0) {
        <div class="grey5 nav-font16 mb-4 pl-2">Quotas have not been added to this project. </div>
        <div class="nav-accent-blue nav-font16 font-weight-bold font-underline cursor-pointer pl-2" (click)="openQuotasSetup()"><u>Click here to add quotas</u></div>
      }
    }
    @else if (fullscreen) {
      <div class="nav-secondary nav-font24 font-weight-bold pb-4">
        Quota Summary
      </div>
      @if (projectQuota?.quotaGroups.length > 0) {
        <div class="quotas-container">
          <div class="quotas-by-group">
            <div class="nav-secondary font-weight-bold nav-font18">Quotas by group</div>
            <div class="cards-container d-flex flex-wrap mt-4">
              @for (group of projectQuota?.quotaGroups; track group.id; let isLast = $last) {
                <div class="card nav-font14 grey1-border py-3" [class.mr-2]="!isLast">
                  <ng-template *ngTemplateOutlet="groupCard; context : { $implicit: group, vendor: null}"></ng-template>
                  <ng-template *ngTemplateOutlet="quotaTotalRow; context: {value1: projectQuota.limitMode == 'complete' ? group.completes : group.starts, value2: group.limitN}"></ng-template>
                </div>
              }
            </div>
          </div>
          @if (projectQuota.enableVendorLimits && !isClient) {
            <div class="pt-4 quotas-by-vendor">
              <div class="nav-secondary font-weight-bold nav-font18">Quotas by vendor</div>
              <div class="cards-container d-flex flex-wrap mt-4">
                @for (vendor of vendors; track vendor.id; let isLast = $last) {
                  <div class="card nav-font14 grey1-border py-3" [class.mr-2]="!isLast">
                    <div class="nav-secondary font-weight-bold px-3 mb-3">{{vendor.partnerName}}</div>
                    <div class="quota-card-body overflow-y">
                      @for (group of projectQuota.quotaGroups; track group.id;) {
                        <ng-template *ngTemplateOutlet="groupCard; context : { $implicit: group, vendor: vendor}"></ng-template>
                      }
                    </div>
                    <ng-template *ngTemplateOutlet="quotaTotalRow; context: {value1: projectQuota.limitMode == 'complete' ? vendor.completes : vendor.starts, value2: vendor.partnerFullQuota}"></ng-template>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      }
      @else {
        <div class="grey5 nav-font16 my-4">Quotas have not been added to this project.</div>
      }
    }
    @if (!fullscreen && projectQuota?.quotaGroups.length > 0) {
      <highcharts-chart
      [style.display]="fullscreen ? 'none' : 'block'"
      id="quotaOverviewChart"
      [Highcharts]="highcharts"
      [options]="highchartsOptions"
      style="width: 320px; height: 100%; display: block;">
    </highcharts-chart>
    }
  </div>
  @if (!fullscreen && projectQuota?.quotaGroups.length > 0) {
    <div class="card-footer pt-4 pb-3">
      <div class="row grey4 nav-font12 mx-0 pb-3 pl-2">
        <div class="mr-3 d-flex align-items-center">
          <span class="legend-icon on-track d-inline-block"></span>
          On track
        </div>
        <div class="mr-3 d-flex align-items-center">
          <span class="legend-icon falling-behind d-inline-block"></span>
          Falling behind 
        </div>
        <div class="d-flex align-items-center">
          <span class="legend-icon off-track d-inline-block"></span>
          Off track
        </div>
      </div>
    </div>
  }
</div>

<ng-template #groupCard let-group let-vendor="vendor">
  <div class="group-card-grid group-name" [ngClass]="vendor ? 'mb-3' : 'mb-4'">
    <div class="font-weight-bold" [ngClass]="vendor ? 'grey6' : 'nav-secondary'">{{group.name}}
    </div>
    @if (group.order == 0 || !vendor) {
      <div class="empty"></div>
      <div class="grey6 font-weight-bold text-center">{{projectQuota.limitMode == 'complete' ? 'Completes' : 'Starts'}}</div>
      <div class="empty"></div>
      <div class="grey6 font-weight-bold text-center">Goal</div>
    }
  </div>
  <div class="overflow-y scrollbar" [class.quota-card-body]="!vendor">
    @for (quota of group.quotas; track quota.id; let lastQuota = $last) {
      @if ( !quota.hideQuota) {
        <div class="group-card-grid mb-3">
          <div class="grey5">
            <ng-template *ngTemplateOutlet="quotaValueText; context : { $implicit: quota, group: group }" ></ng-template>
          </div>
          <div class="empty"></div>
          @if (!vendor) {
            <div class="position-relative">
              <or-progress-guage [value]="(quota[projectQuota.limitMode+'s'] * 100) / quota.limitN" [expected]="daysPercentagePast" 
                [bgColor]="getBgColor((quota[projectQuota.limitMode+'s'] * 100) / quota.limitN)" [bgDanger]="getBgColor((quota[projectQuota.limitMode+'s'] * 100) / quota.limitN)" 
                mode="quota">
              </or-progress-guage>
            </div>
            <div class="empty"></div>
            <div class="grey5 text-center">
              {{projectQuota.limitMode == 'complete' ? quota.completes : quota.starts}}/{{quota.limitN}} n
            </div>
          }
          @else {
            @for (vendorQuota of quota.vendorLimits; track vendorQuota.id; let isLast = $last) {
              @if (vendorQuota.vendorId == vendor.partnerId) {
                <div class="position-relative">
                  <or-progress-guage [value]="(vendorQuota[projectQuota.limitMode+'s'] * 100) / vendorQuota.limitN" [expected]="daysPercentagePast" 
                    [bgColor]="getBgColor((vendorQuota[projectQuota.limitMode+'s'] * 100) / vendorQuota.limitN)" [bgDanger]="getBgColor((vendorQuota[projectQuota.limitMode+'s'] * 100) / vendorQuota.limitN)" 
                    mode="quota">
                  </or-progress-guage>
                </div>
                <div class="empty"></div>
                <div class="grey5 text-center">
                  {{projectQuota.limitMode == 'complete' ? vendorQuota.completes : vendorQuota.starts}}/{{vendorQuota.limitN}} n
                </div>
              }
            }
          }     
        </div>
      }
    }
     
    <!-- Other -->
    @if ((!vendor && group['nonValue' + titleCaseWord(projectQuota.limitMode)+'s'] > 0) ||
    (vendor && vendorGroupMetrics[vendor?.partnerId + '_' + group.id][projectQuota.limitMode+'s'] < vendor[projectQuota.limitMode+'s'])) {
    <div class="group-card-grid mb-3">
      <div class="grey5">
        Other
      </div>
      <div class="empty"></div>
      <div></div>
      <div class="empty"></div>
      <div class="grey5 text-center">
        @if (!vendor) {
          {{group['nonValue' + titleCaseWord(projectQuota.limitMode)+'s']}} n
        }
        @else {
          {{vendor[projectQuota.limitMode+'s'] - vendorGroupMetrics[vendor?.partnerId + '_' + group.id][projectQuota.limitMode+'s']}} n
        }
      </div>
    </div>
  }
  </div>
</ng-template>


<ng-template #quotaTotalRow let-value1="value1" let-value2="value2">
  <div class="horizontal-separator mb-3"></div>
  <div class="group-total-grid pl-3">
    <div class="text-right nav-secondary font-weight-bold">Total</div>
    <div class="empty"></div>
    <div class="position-relative">
      <or-progress-guage [value]="(value1 * 100) / value2" [expected]="daysPercentagePast" 
        [bgColor]="getBgColor((value1 * 100) / value2)" [bgDanger]="getBgColor((value1 * 100) / value2)" 
        mode="quota">
      </or-progress-guage>
    </div>
    <div class="empty"></div>
    <div class="nav-secondary text-center">
      {{value1}}/{{value2}} n
    </div>
  </div>
</ng-template>

<ng-template #quotaValueText let-quota let-group="group">
  @for (name of group.nameArray; track name; let isLast = $last; let isFirst = $first) {
    @if (!quota.isPNTA || isFirst) {
      @if (quota.targetGroup[name.toLowerCase()]; as value) {
        <span [class.font-italic]="value === 'pnta'">{{value === 'pnta' ? 'Prefer not to answer' : value}}</span>
      }
      @else {
        @if (name == 'Age') {
          @if (quota.targetGroup.minAge == -1) {
            <span class="font-italic">Prefer not to answer</span>
          }
          @else {
            <span>{{quota.targetGroup.minAge}} to {{quota.targetGroup.maxAge}}</span>
          }
        }
        @else if (name == 'HouseholdIncome') {
          @if (quota.targetGroup.minHHI == -1) {
            <span class="font-italic">Prefer not to answer</span>
          }
          @else {
            <span>{{quota.targetGroup.minHHI | currency :'USD':'symbol':'1.0-0'}} 
              @if (quota.targetGroup.maxHHI <= hhiMax) {
                to {{quota.targetGroup.maxHHI | currency :'USD':'symbol':'1.0-0'}}
              }
              @else {
                and above
              }
            </span>
          }
        }
      }
    }
    @if (!quota.isPNTA && !isLast && group.nameArray.length > 1) {
      <span>, </span>
    }
  }
</ng-template>