<div class="container-fluid px-2 mx-1" style="z-index: 5;">
  <div class="col d-flex align-items-center px-0 mb-3">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0">
      <i class="far fa-house-window pr-2 fa-lg icon bg-transparent"></i>
      Stage Home - {{_stage?.name}} Summary
    </span>
  </div>

  <div class="homepageCardContainer d-flex"
      [dndDropzone]="['homepage-card']">

    @if (_stage) {
      @for (col of cols; track col.index) {
      <div class="homepage-grid">
        @for (card of col.cards; track card?.id) {
          @if (card.show != false) {

            <div class="grid"
              [dndDropzone]="['homepage-card']"
              (dndDrop)="onDrop($event)"
              [dndDraggable]="card"
              dndDragoverClass="drag-over-highlight"
              dndType="homepage-card"
              dndEffectAllowed="move">

              <div class="text-center pt-1 pr-2 dragIcon" dndHandle>
                <i class="fas fa-grip-dots-vertical fa-xl grey4"></i>
              </div>

              <!-- draggable card -->
              <div class="item" [attr.sortOrder]="card?.sortOrder">
                @if (card?.type === 'dates') {
                <app-project-homepage-dates [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-dates>
                }
                @if (card?.type === 'team') {
                <app-project-homepage-team [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-team>
                }
                @if (card?.type === 'sample-specification') {
                <app-project-homepage-sample [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-sample>
                }
                @if (card?.type === 'schedule') {
                <app-project-homepage-schedule [project]="_project" [stage]="_stage" (callback)="cardCallback($event)"></app-project-homepage-schedule>
                }
                @if (card?.type === 'client') {
                <app-project-homepage-client [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-client>
                }
                @if (card?.type === 'quotas') {
                <app-project-quota-metrics [project]="_project" [respondents]="respondents" (goToQuotaSetup)="cardCallback({section: 'quotas', type: null})" (openQuotaMetricsModal)="openQuotaMetricsModal()"></app-project-quota-metrics>
                }
                @if (card?.type === 'survey-testing') {
                  <app-project-homepage-survey-testing [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-survey-testing>
                }
                @if (card?.type === 'survey-links-vendors') {
                  <app-project-homepage-survey-links-vendors [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-survey-links-vendors>
                }
                @if (card?.type === 'field-management') {
                  <app-project-homepage-field-management [data]="{project: _project, respondents: respondents}" (callback)="cardCallback($event)"></app-project-homepage-field-management>
                }

              @if (card?.type === 'quality') {
                <app-project-homepage-quality [card]="card" [project]="_project" [respondents]="respondents" (callback)="cardCallback($event)"></app-project-homepage-quality>
              }
              @if (card?.type === 'datacleaning') {
              <app-project-homepage-data-cleaning [card]="card" [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-data-cleaning>
              }

                @if (card?.type === 'closure') {
                <app-project-homepage-closure [card]="card" [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-closure>
                }
                @if (card?.type === 'invoice') {
                <app-project-homepage-invoice [card]="card" [project]="_project" (callback)="cardCallback($event)"></app-project-homepage-invoice>
                }
              </div>

            </div>
          }
        }
      </div>
      }
    }
  </div>
</div>
