import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import Highcharts from 'highcharts';
import { QCFlowService } from 'projects/core/src/services/qcFlow.service';

@Component({
  selector: 'app-project-homepage-data-cleaning',
  templateUrl: './project-homepage-data-cleaning.component.html',
  styleUrls: ['./project-homepage-data-cleaning.component.scss']
})
export class ProjectHomepageDataCleaningComponent implements OnInit {

  @Input() public set project(data) {
    this._project = data;
    this.getDataCleaningDetails();
  }

  @Output() callback = new EventEmitter();
  highcharts = Highcharts;
  highchartsOptionsRespondentOverview: Highcharts.Options;
  highchartsOptionsQuestionOverview: Highcharts.Options;
  _project:any = {};
  qcFlowDetails = null;

  constructor( private qcFlowService: QCFlowService,
    private renderer: Renderer2) { }

  ngOnInit() {
  }

  getDataCleaningDetails(): void {
    this.qcFlowService.GetDetails(this._project.id).subscribe((data) => {
      this.qcFlowDetails = data?.qcFlowDetails;
      if(this.qcFlowDetails != null)
        this.createRespondentOverviewChart();
    });
  }

  openDataCleaning() {
    this.callback.emit({section: 'id-suite', type: null});
  }


  
  createRespondentOverviewChart() {
    let chartData = [];
    chartData.push({
      name: 'Accepted', y: this.qcFlowDetails.status.acceptedCount,
      innerSize: '50%',
      className: 'QCFlow-homepage-acceptedSlice'
    })
    chartData.push({ name: 'Pending', y: this.qcFlowDetails.status.respondentCount - (this.qcFlowDetails.status.acceptedCount + this.qcFlowDetails.status.rejectedCount), className: 'QCFlow-homepage-pendingSlice' })
    chartData.push({ name: 'Rejected', y: this.qcFlowDetails.status.rejectedCount, className: 'QCFlow-homepage-rejectedSlice' })

    let totalRespondents = this.qcFlowDetails.status.respondentCount;
    this.highchartsOptionsRespondentOverview = {
      credits: {
        enabled: false
      },
      chart: {
        animation: false,
        plotBorderWidth: null,
        plotBackgroundColor: null,
        plotShadow: false,
        margin: [0, 0, 0, 0],
        events: {
          render: function () {
          },
          load: this.onRespondentOverviewChartLoad.bind(this),
        },
      },

      title: {
        text: `<div class="text-center"><span class="grey5 font-weight-bold nav-font14">${totalRespondents}</span><div class="nav-font14 font-weight-normal grey4 d-block">Respondents</div></div>`,
        align: 'center',
        useHTML: true,
        verticalAlign: 'middle',
        // y: 95
      },
      plotOptions: {
        pie: {
          states: {
            inactive: {
              enabled: false,
            },
          },
          shadow: false,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          borderRadius: '0px',
          showInLegend: false,
          point: {
            events: {
              legendItemClick: function () {
                return false;
              }
            }
          },
          slicedOffset: 5
        },
        series: {
          states: {
            hover: {
              animation: false,
              enabled: false,
            }
          },
          point: {
            events: {
              mouseOver: function (this, e) {
                let newRadius = this.shapeArgs.r + 4;
                this.graphic.animate({
                  r: newRadius,
                  innerR: '48px',
                }, {
                  duration: 300
                });

                this.graphic.shadow({
                  color: 'rgba(1, 59, 92, 0.16)', // Shadow color
                  offsetX: 4, // Shadow offset X
                  offsetY: 4, // Shadow offset Y
                  width: 10 // Shadow width
                });
              },
              mouseOut: function (this,) {
                let newRadius = this.shapeArgs.r;
                this.graphic.animate({
                  r: newRadius,
                  innerR: '50px'
                }, {
                  duration: 300
                });
                this.graphic.shadow(false);
              },
            },
          },
          events: {
            mouseOut: function (this) {
              this.chart.title.attr({
                text: `<div class="text-center"><span class="grey5 nav-font14 font-weight-bold">${totalRespondents}</span><div class="nav-font14 font-weight-normal grey4 d-block">Respondents</div></div>`
              })
              this.update({
                type: 'pie',
                size: '80%'
              })
            },
          }
        },
      },
      series: [{
        type: 'pie',
        innerSize: '100px',
        data: chartData,
        size: '80%'
      }],

      tooltip: {
        animation: false,
        enabled: true,
        borderRadius: 0,
        borderWidth: 0,
        shadow: false,
        shared: true,
        useHTML: false,
        format: null,
        style: {
          opacity: 0
        },
        formatter: function (this, e) {
          e.chart.title.attr({
            y: 100,
            animation: false,
            text: `<div class="text-center nav-font14 font-weight-normal d-block grey4"><span class="grey5 font-weight-bold nav-font14">${this.y}</span><div class="">${this.key}<div>Respondents</div></div>`
          });
          e.chart.tooltip.hide();
          return null
        }
      }
    };
  }

  onRespondentOverviewChartLoad(event) {
    let acceptedSlice = document.getElementsByClassName('QCFlow-homepage-acceptedSlice');
    let rejectedSlice = document.getElementsByClassName('QCFlow-homepage-rejectedSlice');
    let pendingSlice = document.getElementsByClassName('QCFlow-homepage-pendingSlice');
    for (let i = 0; i < acceptedSlice.length; i++) {
      this.renderer.setStyle(acceptedSlice[i], 'fill', '#4CCD96');
      this.renderer.setStyle(acceptedSlice[i], 'fill-opacity', 1);
    }
    for (let i = 0; i < rejectedSlice.length; i++) {
      this.renderer.setStyle(rejectedSlice[i], 'fill', '#D02325CC');
      this.renderer.setStyle(rejectedSlice[i], 'fill-opacity', 1);
    }
    for (let i = 0; i < pendingSlice.length; i++) {
      this.renderer.setStyle(pendingSlice[i], 'fill', '#B5B8BD');
      this.renderer.setStyle(pendingSlice[i], 'fill-opacity', 1);
    }
  }

}
