<div class="card">
  <div class="card-header d-flex align-items-center">
    <span class="nav-secondary nav-font16 font-weight-bold mr-auto mb-0"><i class="fak fa-light-receipt-circle-dollar fa-lg mr-2 grey4 font-weight-normal"></i>Closure</span>
    <div class="d-flex align-items-center justify-content-end">
      <span class="nav-font14 grey4 mr-2">{{percent}}% complete</span>
      <i class="fa-light fa-square-arrow-right fa-lg go-to-page-icon float-right cursor-pointer" (click)="openClosure()"></i>
    </div>
  </div>
  <div class="card-body nav-font14 pt-2">
    <div class="row pb-3">
      <div class="col">
        <div class="rule">
          <div class="progress">
            @if (percent > 5) {
              <div class="progress-bar position-relative" role="progressbar" [style.width.%]="percent" aria-valuemin="0" aria-valuemax="100">
                <span class="dot ml-auto"></span>
              </div>
            }@else {
              <span class="dot blue mr-auto d-block position-relative">
                <span class="dot child d-block"></span>
              </span>
            }
          </div>
        </div>
      </div>
    </div>
    <div class="row align-items-start">
      <div class="col">
        <div class="row-grid mb-2 grey5">
          <div class="nav-font14 font-weight-bold">Current step:</div>
          <div class="nav-font14 pl-0 font-weight-normal">{{steps?.currentStep}}</div>
        </div>
        @if (!closureDetails?.step5CompletedBy) {
          <div class="row-grid grey5">
            <div class="nav-font14 font-weight-bold">Upcoming:</div>
            <div class="nav-font14 pl-0 font-weight-normal">{{steps?.upcomingStep}}</div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
